/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  FormGroup,
  Button,
  Container,
  Card,
  CardBody,
  UncontrolledTooltip,
  Label,
  CustomInput,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import { ValidationError } from 'yup';
import { toast, Flip } from 'react-toastify';
import { serialize } from 'object-to-formdata';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import api from '~/services/api';
import { cpfSchema } from '~/validators/form/new_pix_link';

import Input from '~/components/Inputs/Input';
import InputMask from '~/components/Inputs/InputMask';
import InputSelect from '~/components/Inputs/InputSelect';
import InputCurrency from '~/components/Inputs/InputCurrency';
// import InputSelectAsync from '~/components/Inputs/InputSelectAsync';
import SchemaValidator from '~/validators/form/add_store_form';
// import BankList from '~/Util/banklist';
import PageHeader from '~/components/Common/PageHeader';
import IconButton from '~/components/IconButton';
import {
  bnplGatewayOptions,
  externalGatewayOptions,
  gatewayOptions,
} from '~/util/filters';
import { updateUserRequest } from '~/store/modules/user/actions';
import history from '~/services/history';
import { stateList } from '~/util/stateList';
import ColorPicker from '~/components/ColorPicker';
import ImageInput from '~/components/Inputs/ImageInput';
import { copyStringToClipboard } from '~/util/copyToClipboard';
import { capitalize } from '~/util/format';
import TextAreaWithCounter from '~/components/Inputs/TextAreaWithCounter';
import { isDate, parse, isAfter } from 'date-fns';

const AddStore = () => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const toastId = useRef(null);
  const certificateRef = useRef(null);
  const [searchedCEP, setSearchedCEP] = useState('');
  const [cityList, setCityList] = useState([]);

  // const inputRef = useRef(null);

  const [telephoneV, setTelephone] = useState('');
  const [financierPhone, setFinancierPhone] = useState('');
  const [justaOwnerCellphone, setJustaOwnerCellphone] = useState('');
  const mappLink = useSelector(
    ({ user }) => user?.profile?.subscriber?.mapp_url_webhook
  );

  const [color, setColor] = useState('#ec6f14');
  const [logo, setLogo] = useState(null);
  const [file, setFile] = useState(null);
  const [removeLogo, setRemoveLogo] = useState(false);

  const [certificate, setCertificate] = useState(null);

  const [noNumber, setNoNumber] = useState(() => false);
  const [addressNumber, setAddressNumber] = useState(() => null);

  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [selectedBnplGateway, setSelectedBnplGateway] = useState(
    bnplGatewayOptions[0]
  );
  const [selectedGateway, setSelectedGateway] = useState(gatewayOptions[0]);
  const [externalGateway, setExternalGateway] = useState(false);
  const [splitPayment, setSplitPayment] = useState(false);
  const [clearsaleAppName, setClearsaleAppName] = useState();
  const [pagarmeHub, setPagarmeHub] = useState(null);
  // this state stores fields to keep track after pagarme hub redirections
  const [extraPersistenceFields, setExtraPersistenceFields] = useState({
    online_payments_value_limit: 1000,
    minimum_delivery_balance: 50,
  });

  const [selectedAccountType, setSelectedAccountType] = useState(null);

  const accountTypes = useMemo(
    () => [
      {
        label: 'Poupança',
        value: 'saving',
      },
      {
        label: 'Corrente',
        value: 'checking',
      },
    ],
    []
  );

  const isConstitutionDateValid = value => {
    const parsedDate = isDate(value)
      ? value
      : parse(value, 'dd/MM/yyyy', new Date());

    if (isAfter(parsedDate, new Date())) {
      formRef.current.setFieldError(
        'justa_constitution_date',
        'Data de criação não pode ser no futuro'
      );
      return false;
    }

    return true;
  };

  const isOwnerBirthDateValid = value => {
    const parsedDate = isDate(value)
      ? value
      : parse(value, 'dd/MM/yyyy', new Date());

    if (isAfter(parsedDate, new Date())) {
      formRef.current.setFieldError(
        'justa_owner_birth_date',
        'Data de nascimento não pode ser no futuro'
      );
      return false;
    }

    return true;
  };

  const antifraudOptions = useMemo(
    () =>
      externalGateway
        ? [
            { label: 'Nenhum', value: '' },
            { label: 'ClearSale', value: 'clearsale' },
          ]
        : [{ label: 'Nenhum', value: '' }],
    [externalGateway]
  );

  const [selectedAntifraud, setSelectedAntifraud] = useState(
    antifraudOptions[0]
  );

  useEffect(() => {
    setSelectedAntifraud(antifraudOptions[0]);
  }, [antifraudOptions]);

  useEffect(() => {
    const getCities = async () => {
      const { data } = await api.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState.value}/municipios`
      );

      const parsedCities = data.map(city => ({
        value: city.nome,
        label: city.nome,
      }));

      setCityList(parsedCities);
    };

    if (selectedState) getCities();
  }, [selectedState]);

  useEffect(() => {
    document.title = 'Adicionar Loja | Viddy';
  }, []);

  const adjustMask = v => {
    const digits = !v ? '' : v.replace(/[^\d]/g, '');
    if (!digits || digits.length < 10) return v;
    const cut = digits.length === 10 ? 6 : 7;
    const max = digits.length > 11 ? 11 : digits.length;
    return `(${digits.substring(0, 2)}) ${digits.substring(
      2,
      cut
    )}-${digits.substring(cut, max)}`;
  };

  const maskBuilder = v => {
    if (!v || v.length === 0) return '';
    const mask = adjustMask(v);
    return mask.length >= 6 && mask[5] === '9'
      ? '(99) 99999-9999'
      : '(99) 9999-9999';
  };

  const handleValidSubmit = async data => {
    try {
      data = {
        ...data,
        minimum_delivery_balance: data?.minimum_delivery_balance || 50,
        maximum_delivery_kilometer: data?.maximum_delivery_kilometer || 30,
        online_payments_value_limit: data?.online_payments_value_limit || 1000,
        address: {
          ...data.address,
          city: selectedCity?.value,
          state: selectedState?.value,
        },
        customize: {
          brand_logo: file,
          theme_color: color,
        },
        fantasy_name: capitalize(data.fantasy_name),
      };

      formRef.current.setErrors({});

      const maskTelephoneLength = maskBuilder(telephoneV).length;
      const maskFinancierTelephoneLength = maskBuilder(financierPhone).length;
      const maskJustaOwnerCellphoneLength =
        maskBuilder(justaOwnerCellphone).length;

      await SchemaValidator(
        maskTelephoneLength,
        maskFinancierTelephoneLength,
        maskJustaOwnerCellphoneLength
      ).validate(data, {
        abortEarly: false,
        context: {
          payment_gateway: selectedGateway.value,
        },
      });

      const store = {
        ...data,
        use_malga: externalGateway,
        payment_gateway: selectedGateway.value,
        bnpl_payment_gateway: selectedBnplGateway.value,
        clearsale_app: clearsaleAppName,
        use_split: splitPayment,
        pagarme_hub_key: pagarmeHub?.access_token,
        pagarme_hub_callback: pagarmeHub,
      };

      // Adicionar o objeto justa_establishment se o gateway for Justa
      if (selectedGateway.value === 'justa') {
        store.justa_establishment = {
          alias_name: 'filial',
          cnae: data?.justa_cnae,
          constitution_date: data?.justa_constitution_date,
          owner: {
            name: data?.justa_owner_name,
            legal_document: data?.justa_owner_cpf,
            email: data?.justa_owner_email,
            cellphone: data?.justa_owner_cellphone,
            phone: data?.justa_owner_cellphone,
            birth_date: data?.justa_owner_birth_date,
            rg: data?.justa_owner_rg,
          },
          account_bank: {
            legal_document: data?.justa_account_bank_cpf,
            bank_code: data?.justa_account_bank_code,
            type: selectedAccountType.value,
            agency: data?.justa_account_bank_agency,
            owner_name: data?.justa_account_bank_owner_name,
            number: data?.justa_account_bank_number,
          },
        };
      }

      const storeWithLogo = serialize(store);

      if (selectedGateway.value === 'cielo' && certificate) {
        storeWithLogo.append('certificate', certificate);
      }

      const {
        data: { store: Store },
      } = await api.post(`administrator/stores`, storeWithLogo);

      if (selectedGateway.value === 'cielo') {
        if (Store && certificate) {
          try {
            const formData = new FormData();
            formData.append('certificate', certificate);
            await api.post(
              `administrator/stores/${Store.id}/gerencianet/set-certificate`,
              formData
            );
          } catch (err) {
            console.log(err);
            if (err.response && err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
            }
          }
        }
      }

      toast.success('Loja criada com sucesso!');

      dispatch(updateUserRequest());
      history.push(`/lojas`);
    } catch (err) {
      console.error(err);
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }

      const validationErrors = {};

      if (err instanceof ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      if (err.response && err.response.data.cnpj) {
        formRef.current.setFieldError('cnpj', err.response.data.cnpj[0]);
      }
    }
  };

  const searchCEP = async () => {
    const cep = formRef.current.getFieldValue('address.cep');

    if (searchedCEP === cep) return;
    setSearchedCEP(cep);
    try {
      toastId.current = toast.info(`Buscando por cep: ${cep}...`, {
        autoClose: false,
      });
      const { data } = await api.get(`cep/${cep}`);

      const formData = formRef.current.getData();
      toast.update(toastId.current, {
        render: 'Endereço encontrado!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        transition: Flip,
      });

      formRef.current.setData({
        ...formData,
        address: { ...data },
      });

      const state = stateList.find(s => s.value === data.state);

      setSelectedState(state);

      const city = {
        label: data.city,
        value: data.city,
      };
      setSelectedCity(city);
    } catch (err) {
      toast.update(toastId.current, {
        render: 'Endereço não encontrado!',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        transition: Flip,
      });
    }
  };

  useEffect(() => {
    if (noNumber) {
      setAddressNumber('sn');
    }
  }, [noNumber]);

  const pagarmePublicKey = process.env.REACT_APP_PAGARME_HUB_PUBLIC_KEY;

  const getPagarmeAuthToken = useCallback(
    async authorizationCode => {
      const { data } = await axios.post(
        process.env.REACT_APP_PAGARME_HUB_API_URL,
        {
          code: authorizationCode,
          hub_callback_url: `https://${localStorage.getItem(
            '@VIDDY:X-Server'
          )}.viddy.com.br/api/v1/webhooks/pagarme/hub-callback`,
        },
        {
          headers: {
            PublicAppKey: pagarmePublicKey,
          },
        }
      );

      setPagarmeHub(data);

      return data;
    },
    [pagarmePublicKey]
  );

  const location = useLocation();

  useEffect(() => {
    const searchParams = new window.URLSearchParams(location.search);

    const storeFormState = JSON.parse(sessionStorage.getItem('storeFormState'));
    const authorizationCode = searchParams.get('authorization_code');

    if (!storeFormState || !formRef.current || !authorizationCode) return;

    getPagarmeAuthToken(authorizationCode);

    formRef.current.setData({
      ...formRef.current.getData(),
      ...storeFormState,
    });

    if (storeFormState.city) setSelectedCity(storeFormState.city);
    if (storeFormState.state) setSelectedState(storeFormState.state);
    if (storeFormState.addressNumber)
      setAddressNumber(storeFormState.addressNumber);
    if (storeFormState.noNumber) setNoNumber(storeFormState.noNumber);
    if (storeFormState.brand_logo) setFile(storeFormState.brand_logo);
    if (storeFormState.theme_color) setColor(storeFormState.theme_color);
    if (storeFormState.use_malga) setExternalGateway(storeFormState.use_malga);
    if (storeFormState.payment_gateway)
      setSelectedGateway(storeFormState.payment_gateway);
    if (storeFormState.clearsale_app)
      setClearsaleAppName(storeFormState.clearsale_app);
    if (storeFormState.use_split) setSplitPayment(storeFormState.use_split);
    if (storeFormState.antifraud)
      setSelectedAntifraud(storeFormState.antifraud);
    if (storeFormState.financierPhone)
      setFinancierPhone(storeFormState.financierPhone);
    if (storeFormState) setExtraPersistenceFields(storeFormState);

    sessionStorage.clear();
  }, [getPagarmeAuthToken, location.search]);

  const pagarmeUrl = pagarmeHub?.install_id
    ? {
        href: `${process.env.REACT_APP_PAGARME_HUB_URL}/${pagarmePublicKey}/edit/${pagarmeHub?.install_id}?redirect=${window.location.href}`,
        target: '_blank',
        rel: 'noreferrer',
      }
    : {
        href: `${process.env.REACT_APP_PAGARME_HUB_URL}/${pagarmePublicKey}/authorize?redirect=${window.location.href}`,
      };

  return (
    <div className="page-content">
      <Container className="container new-layout">
        <PageHeader
          pageTitle="CADASTRAR LOJA"
          backTo="/lojas"
          responsivePosition="start"
          desktopPosition="center"
        />
        <Form onSubmit={handleValidSubmit} ref={formRef} noValidate>
          <Card className="card new-layout">
            <CardBody className="card-body new-layout">
              <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                Dados da Loja
              </Row>
              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <FormGroup>
                    <Label>Razão Social</Label>
                    <Input name="name" placeholder="Razão Social" type="text" />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <FormGroup>
                    <Label>Nome Fantasia</Label>
                    <Input
                      name="fantasy_name"
                      placeholder="Nome Fantasia"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <FormGroup>
                    <Label>CNPJ</Label>
                    <InputMask
                      name="cnpj"
                      placeholder="CNPJ"
                      mask="99.999.999/9999-99"
                      maskChar={null}
                      type="text"
                      value={extraPersistenceFields.cnpj}
                      onChange={e =>
                        setExtraPersistenceFields(state => ({
                          ...state,
                          cnpj: e.target.value,
                        }))
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <FormGroup>
                    <Label>Nome do Responsável</Label>
                    <Input
                      name="responsible_name"
                      placeholder="Nome do Responsável"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <FormGroup>
                    <Label>E-mail</Label>
                    <Input name="email" placeholder="E-mail" type="text" />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <FormGroup>
                    <Label>Telefone</Label>
                    <InputMask
                      mask={maskBuilder(telephoneV)}
                      maskChar={null}
                      defaultValue={telephoneV || ''}
                      name="telephone"
                      placeholder="Telefone"
                      type="text"
                      onChange={e => setTelephone(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Scope path="address">
                <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                  Endereço
                </Row>
                <Row className="w-100 mx-0">
                  <Col className="px-0">
                    <FormGroup>
                      <Label>CEP</Label>
                      <InputMask
                        name="cep"
                        placeholder="CEP"
                        mask="99999-999"
                        maskChar={null}
                        value={extraPersistenceFields.cep}
                        onChange={e => {
                          if (e.target.value.length === 9) searchCEP();

                          setExtraPersistenceFields(state => ({
                            ...state,
                            cep: e.target.value,
                          }));
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="w-100 mx-0">
                  <Col lg={6} className="px-0 pr-lg-2">
                    <FormGroup>
                      <Label>Logradouro</Label>
                      <Input
                        name="street_name"
                        placeholder="Logradouro"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2">
                    {/* <FormGroup>
                      <Label>Número</Label>
                      <Input name="number" placeholder="Número" type="text" />
                    </FormGroup> */}
                    <Label>Número</Label>

                    <CustomInput
                      type="switch"
                      id="noNumberSwitch"
                      name="noNumber"
                      label="Sem Número"
                      checked={noNumber}
                      className="text-primary float-right"
                      onChange={() => setNoNumber(!noNumber)}
                    />
                    <FormGroup>
                      <Input
                        name="number"
                        placeholder="Número"
                        type={noNumber ? 'text' : 'number'}
                        disabled={noNumber}
                        onChange={({ target: { value } }) =>
                          setAddressNumber(value)
                        }
                        value={addressNumber || ''}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="w-100 mx-0">
                  <Col lg={6} className="px-0 pr-lg-2">
                    <FormGroup>
                      <Label>Bairro</Label>
                      <Input
                        name="neighborhood"
                        placeholder="Bairro"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2">
                    <TextAreaWithCounter
                      name="complement"
                      placeholder="Complemento"
                      title="Complemento"
                      maxSize={128}
                    />
                  </Col>
                </Row>
                <Row className="w-100 mx-0">
                  <Col lg={6} className="px-0 pr-lg-2">
                    <FormGroup>
                      <Label>Estado</Label>
                      <InputSelect
                        name="state"
                        options={stateList}
                        onChange={selectedOption =>
                          setSelectedState(selectedOption)
                        }
                        value={selectedState}
                        placeholder="Estado"
                        singlePath
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2">
                    <FormGroup>
                      <Label>Cidade</Label>
                      <InputSelect
                        name="city"
                        options={cityList}
                        onChange={selectedOption =>
                          setSelectedCity(selectedOption)
                        }
                        value={selectedCity}
                        placeholder="Cidade"
                        isDisabled={!selectedState}
                        singlePath
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="w-100 mx-0">
                  <Col lg={6} className="px-0 pr-lg-2">
                    <TextAreaWithCounter
                      name="additional_information"
                      placeholder="Informações Adicionais"
                      title="Informações Adicionais"
                      maxSize={128}
                    />
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2">
                    <FormGroup>
                      <Label>Telefone do Financeiro</Label>
                      <InputMask
                        mask={maskBuilder(financierPhone)}
                        maskChar={null}
                        value={financierPhone}
                        name="financier_telephone"
                        placeholder="Celular do financeiro para comunicação mais rápida"
                        type="text"
                        onChange={e => setFinancierPhone(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Scope>

              {/* 
              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <FormGroup>
                    <Label>E-mail do Financeiro</Label>
                    <Input
                      name="financier_email"
                      placeholder="E-mail do financeiro"
                      type="text"
                      tip="Necessário para envio de comprovantes de transferência
                          para aprovação"
                    />
                  </FormGroup>
                </Col>
              </Row>
              */}

              <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                Informações Técnicas
              </Row>
              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <FormGroup>
                    <Label>
                      Limite de valor para pagamentos online
                      <i
                        id="onlinePaymentsLimit"
                        className="las la-info-circle font-size-18 ml-1"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="onlinePaymentsLimit"
                      >
                        Valor máximo de um pagamento online para essa loja,
                        Digite 0 para ilimitado.
                      </UncontrolledTooltip>
                    </Label>
                    <InputCurrency
                      name="online_payments_value_limit"
                      placeholder="Limite de pagamento online"
                      type="text"
                      value={extraPersistenceFields.online_payments_value_limit}
                      onChange={e =>
                        setExtraPersistenceFields(state => ({
                          ...state,
                          online_payments_value_limit: e.target.value,
                        }))
                      }
                      currency
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <FormGroup>
                    <Label>Saldo mínimo no serviço de entrega</Label>
                    <i
                      id="stdMnimumBalance"
                      className="las la-info-circle font-size-18 ml-1"
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target="stdMnimumBalance"
                    >
                      Mínimo de R$ 50,00
                    </UncontrolledTooltip>
                    <InputCurrency
                      name="minimum_delivery_balance"
                      placeholder="Saldo mínimo no serviço de entrega"
                      type="text"
                      value={extraPersistenceFields.minimum_delivery_balance}
                      onChange={e =>
                        setExtraPersistenceFields(state => ({
                          ...state,
                          minimum_delivery_balance: e.target.value,
                        }))
                      }
                      currency
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <FormGroup>
                    <Label>Quantidade máxima de parcelas</Label>
                    <i
                      id="stdMaximumInstallments"
                      className="las la-info-circle font-size-18 ml-1"
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target="stdMaximumInstallments"
                    >
                      O campo “Quantidade máxima de parcelas” funcionará como um
                      limitador de parcelas, no máximo até X parcelas, havendo
                      ou não um valor de parcela mínima.
                    </UncontrolledTooltip>
                    <Input
                      name="maximum_installments"
                      placeholder="Quantidade máxima de parcelas"
                      type="number"
                      defaultValue="12"
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <FormGroup>
                    <Label>Valor da parcela mínima</Label>
                    <i
                      id="stdInstallmentLimit"
                      className="las la-info-circle font-size-18 ml-1"
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target="stdInstallmentLimit"
                    >
                      Se este campo estiver preenchido, toda venda terá um valor
                      limite da parcela, dessa forma vai ser exibido o limite de
                      quantidade de parcelas usando esse valor como base.
                    </UncontrolledTooltip>
                    <InputCurrency
                      name="installment_limit_amount"
                      placeholder="Valor da parcela mínima"
                      type="text"
                      currency
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <FormGroup>
                    <Label>
                      Valor de frete padrão
                      <i
                        id="stdDefaultFreight"
                        className="las la-info-circle font-size-18 ml-1"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="stdDefaultFreight"
                      >
                        O valor informado nesse campo será utilizado no fluxo de
                        nova venda.
                      </UncontrolledTooltip>
                    </Label>
                    <InputCurrency
                      name="default_freight"
                      placeholder="Valor de frete padrão"
                      type="text"
                      currency
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <FormGroup>
                    <Label>Distância máxima em KM para efetuar entregas</Label>
                    <Input
                      name="maximum_delivery_kilometer"
                      placeholder="Distância em KM"
                      type="number"
                      defaultValue="30"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <FormGroup>
                    <Label>Código da Unidade</Label>
                    <i
                      id="userMottuId"
                      className="las la-info-circle font-size-18 ml-1"
                    />
                    <UncontrolledTooltip placement="top" target="userMottuId">
                      Caso a loja utilize o serviço da Mottu Delivery.
                    </UncontrolledTooltip>
                    <Input
                      name="mottu_unit_code"
                      placeholder="Informe o código da sua unidade"
                      type="number"
                      defaultValue="0"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Scope path="customize">
                <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                  Customização por marca
                </Row>
                <Row className="w-100 mx-0">
                  <Col lg={6} className="px-0 pr-lg-2">
                    <FormGroup>
                      <Label>Logo que vai aparecer no link de pagamento</Label>
                      <ImageInput
                        name="brand_logo"
                        setLogo={setLogo}
                        setFile={setFile}
                        setRemoveLogo={setRemoveLogo}
                        removeLogo={removeLogo}
                        logo={logo}
                      />
                      <span className="font-weight-400 font-size-12">
                        Recomendamos que seja em png transparente
                      </span>
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="px-0 pr-lg-2">
                    <FormGroup>
                      <Label>Cor do header do link de pagamento</Label>
                      <ColorPicker color={color} setColor={setColor} />
                    </FormGroup>
                  </Col>
                </Row>
              </Scope>

              <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                Informações de Pagamento
              </Row>
              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <Label>Gateway de pagamento</Label>

                  <CustomInput
                    type="switch"
                    id="externalSwitch"
                    name="externalGateway"
                    label="Externo"
                    checked={externalGateway}
                    className="text-primary float-right"
                    onChange={() => {
                      setExternalGateway(state => !state);
                      setSelectedGateway(gatewayOptions[0]);
                    }}
                  />
                  <FormGroup>
                    <InputSelect
                      name="payment_gateway"
                      className="common-select"
                      options={
                        externalGateway
                          ? externalGatewayOptions
                          : gatewayOptions
                      }
                      onChange={selectedOption => {
                        setSelectedGateway(selectedOption);
                      }}
                      value={selectedGateway || ''}
                      placeholder="Gateway switch"
                      singlePath
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <FormGroup>
                    <Label>Antifraude</Label>
                    <InputSelect
                      name="payment_gateway"
                      className="common-select"
                      options={antifraudOptions}
                      value={selectedAntifraud}
                      onChange={selectedOption => {
                        setSelectedAntifraud(selectedOption);
                      }}
                      placeholder="Selecione antifraude"
                      singlePath
                    />
                  </FormGroup>
                </Col>
              </Row>

              {selectedAntifraud.value === 'clearsale' && (
                <>
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    ClearSale
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Usuário do ClearSale</Label>
                        <Input
                          name="clearsale_name"
                          placeholder="Usuário"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Senha do ClearSale</Label>
                        <Input
                          name="clearsale_password"
                          placeholder="Senha"
                          type="password"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Nome do App</Label>
                        <Input
                          onChange={e => setClearsaleAppName(e.target.value)}
                          value={clearsaleAppName}
                          name="clearsale_app"
                          placeholder="Nome do App"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}

              {selectedGateway.value === 'justa' && (
                <>
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-2">
                    Dados Empresariais
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>CNAE</Label>
                        <Input
                          name="justa_cnae"
                          placeholder="CNAE"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Data de Criação</Label>
                        <InputMask
                          name="justa_constitution_date"
                          placeholder="Data de Criação"
                          mask="99/99/9999"
                          maskChar={null}
                          onBlur={e => isConstitutionDateValid(e.target.value)}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-2">
                    Dados do Responsável
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Nome do Sócio</Label>
                        <Input
                          name="justa_owner_name"
                          placeholder="Nome do Responsável"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Data de Nascimento</Label>
                        <InputMask
                          name="justa_owner_birth_date"
                          placeholder="Data de Nascimento"
                          mask="99/99/9999"
                          maskChar={null}
                          onBlur={e => isOwnerBirthDateValid(e.target.value)}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>RG</Label>
                        <Input
                          name="justa_owner_rg"
                          placeholder="RG"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>CPF</Label>
                        <InputMask
                          name="justa_owner_cpf"
                          placeholder="CPF"
                          mask="999.999.999-99"
                          maskChar={null}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>E-mail</Label>
                        <Input
                          name="justa_owner_email"
                          placeholder="E-mail"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Celular</Label>
                        <InputMask
                          mask={maskBuilder(justaOwnerCellphone)}
                          maskChar={null}
                          name="justa_owner_cellphone"
                          placeholder="Celular"
                          type="text"
                          onChange={e => {
                            if (
                              e.target.value.length < 15 ||
                              e.target.value[5] === '9'
                            ) {
                              setJustaOwnerCellphone(e.target.value);
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-2">
                    Dados Bancários
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Tipo de Conta</Label>
                        <InputSelect
                          name="justa_account_bank_type"
                          options={accountTypes}
                          value={selectedAccountType || ''}
                          onChange={selectedOption =>
                            setSelectedAccountType(selectedOption)
                          }
                          placeholder="Selecione o Tipo de Conta"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Código do Banco</Label>
                        <Input
                          name="justa_account_bank_code"
                          placeholder="Código do Banco"
                          maxLength="3"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Agência</Label>
                        <Input
                          name="justa_account_bank_agency"
                          type="string"
                          placeholder="Agência"
                          maxLength="4"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Número da Conta</Label>
                        <Input
                          name="justa_account_bank_number"
                          type="string"
                          placeholder="Número da Conta"
                          onChange={e => {
                            const value = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
                            if (value.length > 1) {
                              e.target.value = `${value.slice(
                                0,
                                -1
                              )}-${value.slice(-1)}`; // Adiciona hífen antes do último dígito
                            } else {
                              e.target.value = value; // Apenas um dígito, sem hífen
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>CPF</Label>
                        <InputMask
                          name="justa_account_bank_cpf"
                          type="text"
                          placeholder="CPF"
                          mask="999.999.999-99"
                          maskChar={null}
                          onBlur={async e => {
                            try {
                              await cpfSchema.validate(e.target.value);
                            } catch (err) {
                              formRef.current.setFieldError(
                                'justa_account_bank_cpf',
                                err.message
                              );
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Responsável pela Conta</Label>
                        <Input
                          name="justa_account_bank_owner_name"
                          type="text"
                          placeholder="Responsável pela Conta"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}

              {selectedGateway.value === 'cielo' && (
                <>
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Gerência NET
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Client ID</Label>
                        <Input
                          name="gerencianet_client_id"
                          placeholder="Client ID"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Client Secret</Label>
                        <Input
                          name="gerencianet_client_secret"
                          placeholder="Client Secret"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Chave Pix</Label>
                        <Input
                          name="pix_key"
                          placeholder="Chave Pix"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup className="col px-0">
                        <Label className="d-block">Certificado PEM</Label>
                        <Row
                          className={`w-100 mx-0 d-block font-weight-500 text-${
                            certificate ? 'success' : 'gray-700'
                          }`}
                        >
                          <div className="d-inline">
                            {certificate?.name || 'Nenhum arquivo selecionado'}
                          </div>
                          {certificate && (
                            <IconButton
                              id="clean-certificate"
                              icon="las la-times"
                              color="danger"
                              click={() => {
                                certificateRef.current.value = null;
                                setCertificate(null);
                              }}
                              tip="Limpar"
                              forceTip
                              fontSize={18}
                              containerClass="d-inline ml-2"
                            />
                          )}
                          <a
                            onClick={() => certificateRef.current?.click()}
                            className="mt-2 d-block"
                          >
                            {certificate
                              ? 'Escolher outro arquivo'
                              : 'Fazer Upload'}
                          </a>
                        </Row>
                        <input
                          type="file"
                          id="certificate"
                          accept=".pem"
                          ref={certificateRef}
                          className="d-none"
                          onChange={e => {
                            const { files } = e.target;
                            try {
                              setCertificate(files[0]);
                            } catch (err) {
                              console.log(err);
                              if (
                                err.response &&
                                err?.response?.data?.message
                              ) {
                                toast.error(err?.response?.data?.message);
                              }
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Chaves da Cielo
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Client ID</Label>
                        <Input
                          name="clientId"
                          placeholder="Client ID"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Client Secret</Label>
                        <Input
                          name="secret"
                          placeholder="Client Secret"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}

                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Cielo
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Merchant ID</Label>
                        <Input
                          name="cielo_merchant_id"
                          placeholder="Merchant ID"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Merchant Key</Label>
                        <Input
                          name="cielo_merchant_key"
                          placeholder="Merchant Key"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>MID Cybersource (AntiFraude)</Label>
                        <Input
                          name="cielo_provider_id"
                          placeholder="Provider ID"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
              {['pagarme', 'pagarme_hub'].includes(selectedGateway.value) && (
                <>
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    <Col lg={6} className="px-0 pr-lg-2">
                      Pagar.me
                    </Col>
                  </Row>
                  {!externalGateway &&
                  selectedGateway?.value === 'pagarme_hub' ? (
                    <Row className="w-100 mx-0">
                      <Col lg={6} className="px-0 pr-lg-2">
                        <FormGroup>
                          <Button
                            color="primary"
                            size="lg"
                            type="button"
                            className="new-layout-btn new-layout-btn-transparent main d-flex align-items-center p-0 w-100"
                            onClick={() => {
                              let data = formRef.current.getData();
                              data = {
                                ...data,
                                ...extraPersistenceFields,
                                city: selectedCity,
                                state: selectedState,
                                telephone: telephoneV,
                                minimum_delivery_balance:
                                  data.minimum_delivery_balance,
                                maximum_delivery_kilometer:
                                  data.maximum_delivery_kilometer,
                                online_payments_value_limit:
                                  data.online_payments_value_limit,
                                default_freight: data.default_freight,
                                installment_limit_amount:
                                  data.installment_limit_amount,
                                brand_logo: file,
                                theme_color: color,
                                use_malga: externalGateway,
                                payment_gateway: selectedGateway,
                                clearsale_app: clearsaleAppName,
                                use_split: splitPayment,
                                antifraud: selectedAntifraud,
                                financierPhone,
                                addressNumber,
                                noNumber,
                              };
                              sessionStorage.setItem(
                                'storeFormState',
                                JSON.stringify(data)
                              );
                            }}
                          >
                            <a
                              {...pagarmeUrl}
                              className="text-primary w-100 h-100 py-2"
                            >
                              <img
                                src="https://pagar.me/favicon-32x32.png"
                                alt="Ícone do pagar.me"
                                style={{ marginTop: '-2px' }}
                                width={24}
                                height={24}
                              />
                              <span className="ml-3">
                                {pagarmeHub?.install_id
                                  ? 'Visualizar Integração'
                                  : 'Integrar com Pagarme'}
                              </span>
                            </a>
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : null}
                  {!externalGateway && selectedGateway?.value === 'pagarme' ? (
                    <Row className="w-100 mx-0">
                      <Col lg={6} className="px-0 pr-lg-2">
                        <FormGroup>
                          <Label>Chave Pública</Label>
                          <CustomInput
                            type="switch"
                            id="split"
                            name="split"
                            label="Split"
                            checked={splitPayment}
                            className="text-primary float-right"
                            onChange={() => setSplitPayment(!splitPayment)}
                          />
                          <Input
                            name="pagarme_api_public_key"
                            placeholder="Public Key"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} className="px-0 pl-lg-2">
                        <FormGroup>
                          <Label>Chave Secreta</Label>
                          <Input
                            name="pagarme_api_secret_key"
                            placeholder="Private Key"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : null}
                  {externalGateway ? (
                    <Row className="w-100 mx-0">
                      <Col xs={12} className="px-0 pr-lg-2">
                        <FormGroup>
                          <Label>Pagar.me Key</Label>
                          <Input
                            name="pagarme_api_key"
                            placeholder="Pagar.me key"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : null}
                </>
              )}
              {selectedGateway.value === 'pagseguro' && (
                <>
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    PagSeguro
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Token</Label>
                        <Input
                          name="pagseguro_token"
                          placeholder="Token"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>E-mail</Label>
                        <Input
                          name="pagseguro_email"
                          placeholder="E-mail"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
              {selectedGateway.value === 'rede' && (
                <>
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Gerência NET
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Client ID</Label>
                        <Input
                          name="gerencianet_client_id"
                          placeholder="Client ID"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Client Secret</Label>
                        <Input
                          name="gerencianet_client_secret"
                          placeholder="Client Secret"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Chave Pix</Label>
                        <Input
                          name="pix_key"
                          placeholder="Chave Pix"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup className="col px-0">
                        <Label className="d-block">Certificado PEM</Label>
                        <Row
                          className={`w-100 mx-0 d-block font-weight-500 text-${
                            certificate ? 'success' : 'gray-700'
                          }`}
                        >
                          <div className="d-inline">
                            {certificate?.name || 'Nenhum arquivo selecionado'}
                          </div>
                          {certificate && (
                            <IconButton
                              id="clean-certificate"
                              icon="las la-times"
                              color="danger"
                              click={() => {
                                certificateRef.current.value = null;
                                setCertificate(null);
                              }}
                              tip="Limpar"
                              forceTip
                              fontSize={18}
                              containerClass="d-inline ml-2"
                            />
                          )}
                          <a
                            onClick={() => certificateRef.current?.click()}
                            className="mt-2 d-block"
                          >
                            {certificate
                              ? 'Escolher outro arquivo'
                              : 'Fazer Upload'}
                          </a>
                        </Row>
                        <input
                          type="file"
                          id="certificate"
                          accept=".pem"
                          ref={certificateRef}
                          className="d-none"
                          onChange={e => {
                            const { files } = e.target;
                            try {
                              setCertificate(files[0]);
                            } catch (err) {
                              console.log(err);
                              if (
                                err.response &&
                                err?.response?.data?.message
                              ) {
                                toast.error(err?.response?.data?.message);
                              }
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Rede
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Merchant ID</Label>
                        <Input
                          name="rede_merchant_id"
                          placeholder="Merchant ID"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>API Key</Label>
                        <Input
                          name="rede_api_key"
                          placeholder="API Key"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
              {selectedGateway.value === 'mercadopago' && (
                <>
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Mercado Pago
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={12} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Access Key</Label>
                        <Input
                          name="mercadopago_access_token"
                          placeholder="Access Key"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}

              <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                Pix Parcelado
              </Row>
              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <Label>Gateway de pagamento</Label>

                  <FormGroup>
                    <InputSelect
                      name="bnpl_payment_gateway"
                      className="common-select"
                      options={bnplGatewayOptions}
                      onChange={selectedOption => {
                        setSelectedBnplGateway(selectedOption);
                      }}
                      value={selectedBnplGateway || ''}
                      placeholder="Gateway switch"
                      singlePath
                    />
                  </FormGroup>
                </Col>
              </Row>

              {selectedBnplGateway.value === 'koin' && (
                <>
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Koin
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Chave Privada</Label>
                        <Input
                          name="koin_private_key"
                          placeholder="Chave Privada"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Número da Conta</Label>
                        <Input
                          name="koin_account"
                          placeholder="Número da Conta"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Identificador da Conta</Label>
                        <Input
                          name="koin_store_code"
                          placeholder="Identificador da Conta"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}

              <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                Informações CRM Bônus
              </Row>

              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <FormGroup>
                    <Label>Código da Empresa</Label>
                    <Input
                      name="crmbonus_company_code"
                      placeholder="Código da Empresa"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <FormGroup>
                    <Label>ID da Loja</Label>
                    <Input
                      name="crmbonus_store_id"
                      placeholder="ID da Loja"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                Informações Frota Alternativa
              </Row>

              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <FormGroup>
                    <Label>Credenciamento (Mapp)</Label>
                    <Input
                      name="mapp_client"
                      placeholder="Código do Cliente"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <FormGroup>
                    <Label>E-mail para notificações</Label>
                    <Input
                      name="email_delivery_notification"
                      placeholder="E-mail da loja"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="w-100 mx-0 align-items-end">
                <Col lg={6} className="px-0 pr-lg-2">
                  <FormGroup>
                    <Label>URL de Notificações (Webhook)</Label>
                    <Input
                      name="url_delivery_notification"
                      placeholder="URL para consulta"
                      type="text"
                      disabled
                      value={mappLink}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <FormGroup>
                    <Button
                      color="primary"
                      size="lg"
                      type="button"
                      className="new-layout-btn new-layout-btn-transparent main d-flex align-items-center"
                      onClick={() => {
                        copyStringToClipboard(mappLink, 'URL copiado!');
                      }}
                    >
                      <i className="las la-copy" />
                      Copiar URL
                    </Button>
                  </FormGroup>
                </Col>
              </Row>

              <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                Informações Uber
              </Row>

              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <FormGroup>
                    <Label>Customer ID</Label>
                    <Input
                      name="uber_customer_id"
                      placeholder="Customer ID da Loja na Uber"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>

              {/* <Scope path="bank_data">
                <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                  Informações Bancárias
                </Row>
                <Row className="w-100 mx-0">
                  <Col className="px-0">
                    <FormGroup>
                      <Label>Instituição</Label>
                      <InputSelect
                        name="institution"
                        className="common-select"
                        placeholder="Instituição"
                        options={BankList}
                        onChange={selectedOption =>
                          setBankSelected(selectedOption)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="w-100 mx-0">
                  <Col lg={6} className="px-0 pr-lg-2">
                    <FormGroup>
                      <Label>Tipo de Documento</Label>
                      <InputSelect
                        name="document_type"
                        options={[
                          { value: 'CNPJ', label: 'CNPJ' },
                          { value: 'CPF', label: 'CPF' },
                        ]}
                        onChange={selectedOption =>
                          setMask(selectedOption.value)
                        }
                        placeholder="Selecione o tipo do documento"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2">
                    <FormGroup>
                      <Label>Número do Documento</Label>
                      <InputMask
                        name="document"
                        mask={
                          maskType === 'CNPJ'
                            ? '99.999.999/9999-99'
                            : '999.999.999-99'
                        }
                        maskChar={null}
                        placeholder="Número do documento"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="w-100 mx-0">
                  <Col lg={isCaixa ? 4 : 6} className="px-0 pr-lg-2">
                    <FormGroup>
                      <Label>Conta</Label>
                      <Input
                        name="account_number"
                        placeholder="Número da conta da conta bancária com dígito"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col
                    lg={isCaixa ? 4 : 6}
                    className={`px-0 ${isCaixa ? 'px-lg-2' : 'pl-lg-2'}`}
                  >
                    <FormGroup>
                      <Label>Agência</Label>
                      <Input name="agency" placeholder="Agência" type="text" />
                    </FormGroup>
                  </Col>
                  {isCaixa && (
                    <Col lg={4} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Operação</Label>
                        <Input
                          name="operation"
                          placeholder="Operação"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </Scope> */}
            </CardBody>
          </Card>
          <Row className="w-100 mx-0 my-4 ">
            <Col align="end" className="px-0">
              <Button
                className="new-layout-btn main"
                color="primary"
                size="lg"
                type="submit"
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default AddStore;
