import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import IntlCurrencyInput from 'react-intl-currency-input';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
// import QRCodeLib from 'react-qr-code';
import PixQrCodeModal from '~/components/Pix/PixQrCodeModal';
import { finishCartLink } from '~/store/modules/cart/actions';
import InputSelect from '~/components/Inputs/InputSelect';
// import Loading from '~/components/Loading';
import { getRole } from '~/util/getRole';
import api from '~/services/api';
import {
  formatPrice,
  formatPriceToNumber,
  formatWhatsappPhoneNumber,
} from '~/util/format';
import GiftbackCard from '~/components/GiftbackCard';

import CartProducts from './CartProducts';

const Payment = ({ finishCartState, setFinishCartState }) => {
  const formRef = useRef(null);
  const tooltip = useRef(null);

  const dispatch = useDispatch();

  const roles = useSelector(({ user }) => user.profile.roles);
  const cartState = useSelector(({ cart }) => cart.cart);
  const link_cielo = useSelector(({ cart }) => cart.link_cielo);
  const link_pagarme = useSelector(({ cart }) => cart.link_pagarme);
  const link_mercadopago = useSelector(({ cart }) => cart.link_mercadopago);
  const link_rede = useSelector(({ cart }) => cart.link_rede);
  const link_pagseguro = useSelector(({ cart }) => cart.link_pagseguro);
  // const cartQrCode = useSelector(({ cart }) => cart.qrcode);
  const done = useSelector(({ cart }) => cart.done);

  const subscriberId = localStorage.getItem('@VIDDY:X-Server');
  const subscriberNomura = '6888af2c-b1c1-431d-a7e6-aea35d2f0286';
  const subscriberNomuraShoes = '83afc2b0-8090-436e-ae8c-8a364ee9839e';
  // const subscriberNomura = '04c1ecc3-fe7a-4d26-880f-5cb1689c79fe';
  const subscriberViddyComercial = '504c2f33-f3af-411c-b6f4-c9c483f8b745';
  const viddyTax = 1.99;
  const freightTax = 1.16;

  const getPaymentTypeByCode = () => {
    if (finishCartState?.data?.payment_type === 0) return { type: 'Link' };

    if (finishCartState?.data?.payment_type === 201)
      return { type: 'Card', method: 'Credit' };

    if (finishCartState?.data?.payment_type === 202)
      return { type: 'Card', method: 'Debit' };

    if (finishCartState?.data?.payment_type === 204)
      return { type: 'Card', method: 'Pix' };

    if (finishCartState?.data?.payment_type === 203) return { type: 'Money' };

    if (finishCartState?.data?.payment_type === 401) return { type: 'Pago' };

    if (cartState?.payment_type === 0) return { type: 'Link' };

    if (cartState?.payment_type === 201)
      return { type: 'Card', method: 'Credit' };

    if (cartState?.payment_type === 202)
      return { type: 'Card', method: 'Debit' };

    if (cartState?.payment_type === 204) return { type: 'Card', method: 'Pix' };

    if (cartState?.payment_type === 203) return { type: 'Money' };

    if (cartState?.payment_type === 401) return { type: 'Pago' };

    return { type: '' };
  };

  const [changeErrorMessage, setChangeErrorMessage] = useState(null);
  const [changeValue, setChangeValue] = useState(
    cartState?.payment_process?.change || null
  );
  const [paymentType, setPaymentType] = useState(getPaymentTypeByCode().type);
  const [paymentMethod, setPaymentMethod] = useState(
    getPaymentTypeByCode().method
  );
  const [changeInput, setChangeInput] = useState(
    cartState?.payment_process?.change === '0.00'
  );
  // const [changeInputValue, setChangeInputValue] = useState();
  const [freightValueAmount, setFreightValueAmount] = useState(null);
  const [returnFreightValueAmount, setReturnFreightValueAmount] =
    useState(null);
  const [defaultFreight, setDefaultFreight] = useState(null);
  const [
    freightAlreadyPayed,
    // setFreightAlreadyPayed,
  ] = useState(null);
  const [freightWillBePayed, setFreightWillBePayed] = useState(
    defaultFreight > 0
      ? defaultFreight
      : finishCartState?.userFreight || cartState?.freight_user || 0
  );
  const [totalValueError, setTotalValueError] = useState(null);

  const initializeCheckoutMethods = useCallback(() => {
    return [true, true, !!cartState?.store?.bnpl_payment_gateway];
  }, [cartState?.store?.bnpl_payment_gateway]);

  const [checkoutMethods, setCheckoutMethods] = useState(
    initializeCheckoutMethods
  );
  useEffect(() => {
    setCheckoutMethods(initializeCheckoutMethods());
  }, [initializeCheckoutMethods]);
  const [checkoutMethodsError, setCheckoutMethodsError] = useState('');

  const [showPixQrCodeModal, setShowPixQrCodeModal] = useState(false);
  const [loadingQrCode, setLoadingQrCode] = useState(false);
  const [pixQrCode, setPixQrCode] = useState(false);

  const [maxInstallments, setMaxInstallments] = useState(1);
  const [installmentsOptions, setInstallmentsOptions] = useState([]);
  const [selectedInstallment, setSelectedInstallment] = useState(null);

  const [errorMessage, setErrorMessage] = useState('');
  const [isGiftbackActive, setIsGiftbackActive] = useState(false);

  const [saldoDisponivel, setSaldoDisponivel] = useState(0.0);
  const [saldoTotal, setSaldoTotal] = useState(0.0);
  const [valorMinimoResgate, setValorMinimoResgate] = useState(0.0);
  const [dataExpiracao, setDataExpiracao] = useState('');
  const [isLoadingGiftback, setIsLoadingGiftback] = useState(false);
  const [hasGiftbackError, setHasGiftbackError] = useState(false);
  const [hasFreightError, setHasFreightError] = useState(false);
  const [isFreightLoading, setIsFreightLoading] = useState(false);

  const [products, setProducts] = useState([]);

  const [total, setTotal] = useState(
    finishCartState?.amount || cartState?.amount || 0
  );

  const handleNoChangeClick = () => {
    setChangeInput(!changeInput);

    if (!changeInput) {
      setChangeValue(0);
      formRef.current.setFieldError('change', '');
    }
  };

  const calculateProductsTotal = useCallback(() => {
    if (products.length === 0) return 0;

    return products.reduce(
      (acc, product) =>
        acc + Number(product.value) * Number(product.prod_amount),
      0
    );
  }, [products]);

  const updateTotal = useCallback(productsTotal => {
    const newTotal = Number(productsTotal);
    setTotal(newTotal);
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      const productsTotal = calculateProductsTotal();
      updateTotal(productsTotal);
    } else {
      setTotal(0);
    }
  }, [products, calculateProductsTotal, updateTotal]);

  const handleGiftbackSwitchChange = isActive => {
    setIsGiftbackActive(isActive);
  };

  const useUserAddress = useSelector(({ cart }) => cart.useUserAddress);
  const address = useSelector(({ cart }) => cart.address);

  const deliveryFleet = useSelector(({ cart }) => cart.deliveryFleet);
  // const totalAcumulator = useSelector(({ cart }) => cart.totalAcumulator);

  const [handleDisabled, setHandleDisabled] = useState(false);

  const [
    cuponNumber,
    // setCuponNumber,
  ] = useState(cartState?.nfce?.number || null);

  const storeOnlinePaymentLimit = useSelector(({ cart }) => {
    return Number(cart.cart?.store?.online_payments_value_limit);
  });

  const linkPaymentAvailable = useMemo(() => {
    if (
      cartState?.store?.has_cielo_merchant_keys ||
      cartState?.store?.has_pagarme_key ||
      cartState?.store?.has_mercadopago_key ||
      cartState?.store?.has_rede_key ||
      cartState?.store?.has_pagseguro_key ||
      cartState?.store?.has_justa_key
    ) {
      return true;
    }
    return false;
  }, [cartState]);

  const bnplPaymentAvailable = useMemo(() => {
    if (cartState?.store?.has_koin_key) return true;
    return false;
  }, [cartState]);

  const formatCurrency = value => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  useEffect(() => {
    let maxInstallments2 = 1;
    const totalWithFreight =
      parseFloat(total || 0) +
      parseFloat(freightWillBePayed || 0) -
      (isGiftbackActive ? saldoDisponivel : 0);

    if (cartState?.store?.installment_limit_amount > 0) {
      if (totalWithFreight > 0) {
        maxInstallments2 = Math.floor(
          totalWithFreight / cartState.store.installment_limit_amount
        );
      } else {
        maxInstallments2 = 1; // Caso o total seja 0 e apenas o frete seja considerado
      }
    } else {
      maxInstallments2 = 12; // Exibir até 12 parcelas se não houver installment_limit_amount
    }

    // Aplica a nova regra de quantidade máxima de parcelas
    const maxStoreInstallments = cartState?.store?.maximum_installments || 12; // Se não tiver definido, assume 12
    setMaxInstallments(Math.min(maxInstallments2, maxStoreInstallments));
  }, [cartState, total, freightWillBePayed, isGiftbackActive, saldoDisponivel]);

  useEffect(() => {
    const totalWithFreight =
      parseFloat(total || 0) +
      parseFloat(freightWillBePayed || 0) -
      (isGiftbackActive ? saldoDisponivel : 0);

    const options = [];

    if (totalWithFreight > 0) {
      const maxInstallmentsToUse = maxInstallments > 0 ? maxInstallments : 1;

      // Aplicar a regra do máximo de parcelas definido pela loja
      const maxStoreInstallments = cartState?.store?.maximum_installments || 12;
      const finalMaxInstallments = Math.min(
        maxInstallmentsToUse,
        maxStoreInstallments
      );

      for (let i = 1; i <= finalMaxInstallments; i += 1) {
        const installmentValue = (totalWithFreight / i).toFixed(2);
        options.push({
          value: i,
          label: `${i}x de ${formatCurrency(installmentValue)}`,
        });
      }
    } else {
      const inputValue =
        parseFloat(total || 0) +
        parseFloat(freightWillBePayed || 0) -
        (isGiftbackActive ? saldoDisponivel : 0);
      options.push({
        value: 1,
        label: `1x de ${formatCurrency(inputValue.toFixed(2))}`,
      });
    }

    setInstallmentsOptions(options);
    setSelectedInstallment(options[0]); // Selecionar automaticamente a primeira parcela
  }, [
    maxInstallments,
    total,
    freightWillBePayed,
    isGiftbackActive,
    saldoDisponivel,
    cartState,
  ]);

  useEffect(() => {
    // Atualizar parcelas quando o valor do frete estiver disponível desde o início
    if (freightWillBePayed > 0 && total === 0) {
      const maxStoreInstallments = cartState?.store?.maximum_installments || 12;

      const totalWithFreight =
        parseFloat(total) +
        parseFloat(freightWillBePayed) -
        (isGiftbackActive ? saldoDisponivel : 0);

      // Ajusta a quantidade de parcelas com base na nova regra
      let maxInstallmentsToUse = 1;
      if (totalWithFreight > 0) {
        maxInstallmentsToUse = Math.floor(
          totalWithFreight / cartState.store.installment_limit_amount
        );
      }

      // Respeitar a quantidade máxima de parcelas configurada pela loja
      const finalMaxInstallments = Math.min(
        maxInstallmentsToUse,
        maxStoreInstallments
      );

      setMaxInstallments(finalMaxInstallments);

      setInstallmentsOptions([
        {
          value: finalMaxInstallments,
          label: `${finalMaxInstallments}x de ${formatCurrency(
            (totalWithFreight / finalMaxInstallments).toFixed(2)
          )}`,
        },
      ]);

      setSelectedInstallment({
        value: finalMaxInstallments,
        label: `${finalMaxInstallments}x de ${formatCurrency(
          (totalWithFreight / finalMaxInstallments).toFixed(2)
        )}`,
      });
    }
  }, [freightWillBePayed, total, isGiftbackActive, saldoDisponivel, cartState]);

  /*
  const getGiftbackData = useCallback(async (totalValue) => {
    if (!cartState.id) return;

    setIsLoadingGiftback(true);

    try {
      const { data: { giftback } } = await api.get(`${getRole(roles)}/carts/${cartState.id}/giftback/${totalValue}`);
      setSaldoDisponivel(parseFloat(giftback.amount));
      setValorMinimoResgate(parseFloat(giftback.min_purchase));
      setDataExpiracao(giftback.expiration_date);
    } catch (error) {
      console.error("Erro ao buscar dados do Giftback:", error);
    } finally {
      setIsLoadingGiftback(false);
    }
  }, [cartState.id, roles]);
  */

  useEffect(() => {
    // setFreightValueAmount(null);
    setReturnFreightValueAmount(null);
    setDefaultFreight(null);

    if (total > 0 && !hasGiftbackError) {
      const getGiftbackData = async () => {
        if (!cartState.id) return;

        setIsLoadingGiftback(true);

        try {
          const {
            data: { giftback },
          } = await api.get(
            `${getRole(roles)}/carts/${cartState.id}/giftback/${total}`
          );
          setSaldoDisponivel(parseFloat(giftback.amount));
          setSaldoTotal(parseFloat(giftback.total_amount));
          setValorMinimoResgate(parseFloat(giftback.min_purchase));
          setDataExpiracao(giftback.expiration_date);
        } catch (error) {
          console.error('Erro ao buscar dados do Giftback:', error);
          setHasGiftbackError(true);
        } finally {
          setIsLoadingGiftback(false);
        }
      };

      getGiftbackData();
    }

    if (
      cartState?.is_for_delivery &&
      (subscriberId === subscriberNomura ||
        subscriberId === subscriberNomuraShoes) &&
      (cartState?.delivery_method !== 'CORREIOS' ||
        cartState?.delivery_method !== 'LALAMOVE')
    ) {
      setDefaultFreight(cartState?.store?.default_freight || 0);
      setFreightWillBePayed(cartState?.store?.default_freight || 0);
    }

    /*
    if (
      cartState?.is_for_delivery &&
      cartState?.store?.default_freight !== undefined &&
      cartState?.store?.default_freight > 0 &&
      subscriberId === subscriberNomura &&
      (cartState?.delivery_method !== 'CORREIOS' ||
        cartState?.delivery_method !== 'LALAMOVE')
    ) {
      setDefaultFreight(cartState?.store?.default_freight || 0);
      setFreightWillBePayed(cartState?.store?.default_freight || 0);
    }
    */

    if (
      (subscriberId === subscriberNomura ||
        subscriberId === subscriberNomuraShoes) &&
      cartState?.delivery_method === 'CORREIOS'
    ) {
      setFreightWillBePayed(parseFloat(30));
    }

    if (
      (subscriberId === subscriberNomura ||
        subscriberId === subscriberNomuraShoes) &&
      cartState?.delivery_method === 'LALAMOVE'
    ) {
      setFreightWillBePayed(parseFloat(20));
    }
  }, [
    total,
    cartState,
    roles,
    address,
    paymentType,
    hasGiftbackError,
    hasFreightError,
    freightValueAmount,
    isFreightLoading,
    subscriberId,
  ]);

  useEffect(() => {
    if (!cartState.id || !paymentType) return;

    const paymentTable = {
      Link: 0,
      Card: 201,
      Money: 203,
      Pago: 401,
    };

    const getPaymentCodeByType = () => paymentTable[paymentType] || 0;

    const getFreight = async () => {
      setIsFreightLoading(true);

      try {
        const {
          data: { freight, return_freight },
        } = await api.get(
          `${getRole(roles)}/carts/${
            cartState.id
          }/delivery/freight/${getPaymentCodeByType()}`
        );

        setFreightValueAmount(freight || 0);
        setReturnFreightValueAmount(return_freight || 0);
        setHasFreightError(false);
      } catch (error) {
        console.error('Erro ao buscar dados do frete:', error);
        setFreightValueAmount(0);
        setReturnFreightValueAmount(0);
        setHasFreightError(true);
      } finally {
        setIsFreightLoading(false);
      }
    };

    getFreight();
  }, [paymentType, cartState.id, roles]);

  useEffect(() => {
    if (
      cartState?.is_for_delivery &&
      cartState?.store?.default_freight !== undefined &&
      cartState?.store?.default_freight > 0
    ) {
      setDefaultFreight(cartState.store.default_freight);
      setFreightWillBePayed(cartState?.store?.default_freight);
    } else {
      setFreightWillBePayed(
        finishCartState?.userFreight || cartState?.freight_user || 0
      );
    }
  }, [cartState, defaultFreight, finishCartState]);

  useEffect(() => {
    if (
      cartState.is_for_delivery &&
      subscriberId === subscriberViddyComercial
    ) {
      setFreightWillBePayed(freightValueAmount * freightTax);
    }
  }, [cartState, freightValueAmount, subscriberId, subscriberViddyComercial]);

  useEffect(() => {
    if (
      cartState.is_for_delivery &&
      subscriberId === subscriberViddyComercial
    ) {
      setFreightWillBePayed(freightValueAmount * freightTax);
    }
  }, [cartState, freightValueAmount, subscriberId, subscriberViddyComercial]);

  const PAYMENTS_TYPES = useMemo(
    () => [
      {
        id: 1,
        available: linkPaymentAvailable || bnplPaymentAvailable,
        label: `${cartState?.store?.payment_gateway ? 'Cartão / Pix' : ''}${
          cartState?.store?.bnpl_payment_gateway ? ' / Pix Parcelado' : ''
        }`,
        tag: 'Link',
        icon: 'fas fa-link',
        value: false,
        col_padding: 'px-0',
        checked: paymentType === 'Link',
        col_width: 3,
      },
      // {
      //   id: 2,
      //   available: pixPaymentAvailable,
      //   label: 'Pix',
      //   tag: 'Pix',
      //   icon: 'las la-university',
      //   value: false,
      //   col_padding: 'px-0 pr-lg-2',
      //   checked: paymentType === 'Pix',
      //   col_width: 3,
      // },
      {
        id: 2,
        available:
          deliveryFleet !== 'RETIRAR' &&
          deliveryFleet !== 'UBER' &&
          subscriberId !== subscriberViddyComercial,
        label: 'Maquineta / POS',
        tag: 'Card',
        icon: 'las la-credit-card',
        value: false,
        col_padding: 'px-0 pl-lg-2',
        checked: paymentType === 'Card',
        col_width: 3,
      },
      {
        id: 3,
        available:
          deliveryFleet !== 'RETIRAR' &&
          deliveryFleet !== 'UBER' &&
          subscriberId !== subscriberViddyComercial,
        label: 'Dinheiro',
        tag: 'Money',
        icon: 'las la-money-bill',
        value: false,
        col_padding: 'px-0 pl-lg-2',
        checked: paymentType === 'Money',
        col_width: 3,
      },
      {
        id: 6,
        available:
          deliveryFleet !== 'RETIRAR' &&
          subscriberId !== subscriberViddyComercial,
        label: 'Pedido Pago - Só Entrega',
        tag: 'Pago',
        icon: 'las la-money-check-alt',
        value: false,
        col_padding: 'px-0 pl-lg-2',
        checked: paymentType === 'Pago',
        col_width: 3,
      },
    ],
    [
      linkPaymentAvailable,
      bnplPaymentAvailable,
      paymentType,
      // pixPaymentAvailable,
      deliveryFleet,
      cartState?.store,
      subscriberId,
      subscriberViddyComercial,
    ]
  );

  const handleFinishOrder = () => {
    if (
      (paymentMethod && paymentType !== 'Link') ||
      paymentType === 'Money' ||
      paymentType === 'Pago' ||
      paymentType === 'Link'
    ) {
      formRef.current.submitForm();
    }
  };

  const handleSubmit = useCallback(
    async data => {
      setHandleDisabled(true);
      const { installments } = data;
      let NumberInstallments;
      let finalAmount = total;

      if (subscriberId === subscriberViddyComercial) {
        finalAmount += viddyTax;
      }

      if (
        subscriberId === subscriberViddyComercial &&
        (freightValueAmount === 0 || freightValueAmount == null)
      ) {
        toast.error('Valor de frete não identificado!');
        setHandleDisabled(false);
        return;
      }

      if (paymentType !== 'Pago' && !total && !freightWillBePayed) {
        setTotalValueError('Esse valor é inválido');
        setHandleDisabled(false);
        return;
      }

      if (cartState.is_for_delivery && !useUserAddress && !address) {
        toast.error('Confirme o endereço');
        setHandleDisabled(false);
        return;
      }

      if (paymentType === 'Money' && !changeInput) {
        try {
          formRef.current.setErrors({});

          if (changeInput) {
            setChangeValue(0);
          } else if (changeValue <= 0) {
            formRef.current.setFieldError(
              'change',
              'Valor de troco deve ser maior que zero.'
            );
            setHandleDisabled(false);
            return;
          }

          const schema = Yup.object().shape({
            change: Yup.number()
              .min(
                total,
                'Para que haja troco, o valor inserido deve ser maior do que o valor da compra.'
              )
              .required('O valor do troco é obrigatório.'),
          });

          await schema.validate(
            { change: changeValue },
            {
              abortEarly: false,
            }
          );
        } catch (err) {
          console.error(err);
          const validationErrors = {};

          if (err instanceof Yup.ValidationError) {
            err.inner.forEach(e => {
              validationErrors[e.path] = e.message;
            });
            formRef.current.setErrors(validationErrors);
          }
          if (err.response && err?.response?.data?.message) {
            toast.error(err?.response?.data?.message, {
              autoClose: err?.response?.data?.duration || 5000,
            });
          }
          setHandleDisabled(false);
          return;
        }
      }

      if (installments === '') {
        NumberInstallments = 1;
      } else {
        NumberInstallments = Number(installments);
      }

      if (NumberInstallments < 1 || NumberInstallments > 12) {
        toast.error('Número de parcelas inválido!');
        setHandleDisabled(false);
        return;
      }

      if (cartState.is_cross && products.length < 1) {
        toast.error('É necessário adicionar produtos para avancar!');
        setHandleDisabled(false);
        return;
      }

      const giftbackData = isGiftbackActive
        ? {
            useGiftback: true,
            giftback_amount: saldoDisponivel,
          }
        : {};

      if (paymentType === 'Card') {
        let paymentData;

        if (paymentMethod === 'Credit') {
          paymentData = {
            payment_type: 201,
            installments: selectedInstallment.value || 1,
          };
        } else if (paymentMethod === 'Debit') {
          paymentData = { payment_type: 202 };
        } else if (paymentMethod === 'Pix') {
          paymentData = { payment_type: 204 };
        }

        setFinishCartState(prevState => ({
          ...prevState,
          data: paymentData,
          cart_id: cartState.id,
          products,
          amount: finalAmount,
          nfce: cuponNumber,
          userFreight: deliveryFleet !== 'RETIRAR' ? freightWillBePayed : 0,
          freight: freightValueAmount,
          return_freight: returnFreightValueAmount,
          default_freight: defaultFreight,
          ...giftbackData,
        }));
      }
      if (
        paymentType === 'Pix' &&
        cartState.store?.has_pix_key &&
        cartState.store?.payment_gateway === 'cielo'
      ) {
        dispatch(
          finishCartLink(prevState => ({
            ...prevState,
            payment_type: 105,
            cart_id: cartState.id,
            products,
            amount: finalAmount,
            nfce: cuponNumber,
            page_state: {
              setLoadingQrCode,
              setPixQrCode,
              setShowPixQrCodeModal,
              setHandleDisabled,
            },
            ...giftbackData,
          }))
        );
      }
      if (
        paymentType === 'Pix' &&
        cartState.store?.has_pix_key &&
        cartState.store?.payment_gateway === 'rede'
      ) {
        dispatch(
          finishCartLink(prevState => ({
            ...prevState,
            payment_type: 105,
            cart_id: cartState.id,
            products,
            amount: finalAmount,
            nfce: cuponNumber,
            page_state: {
              setLoadingQrCode,
              setPixQrCode,
              setShowPixQrCodeModal,
              setHandleDisabled,
            },
            ...giftbackData,
          }))
        );
      }
      if (
        paymentType === 'Pix' &&
        cartState.store?.payment_gateway === 'pagseguro'
      ) {
        dispatch(
          finishCartLink(prevState => ({
            ...prevState,
            payment_type: 114,
            cart_id: cartState.id,
            products,
            amount: finalAmount,
            nfce: cuponNumber,
            page_state: {
              setLoadingQrCode,
              setPixQrCode,
              setShowPixQrCodeModal,
              setHandleDisabled,
            },
            ...giftbackData,
          }))
        );
      }
      if (paymentType === 'Pago') {
        setFinishCartState(prevState => ({
          ...prevState,
          data: {
            payment_type: 401,
          },
          cart_id: cartState.id,
          products,
          amount: finalAmount || 0,
          nfce: cuponNumber,
          userFreight: deliveryFleet !== 'RETIRAR' ? freightWillBePayed : 0,
          freight: freightValueAmount,
          return_freight: returnFreightValueAmount,
          default_freight: defaultFreight,
          freightAlreadyPayed,
          ...giftbackData,
        }));
      }
      if (
        paymentType === 'Pix' &&
        ['pagarme', 'pagarme_hub'].includes(cartState.store?.payment_gateway)
      ) {
        dispatch(
          finishCartLink(prevState => ({
            ...prevState,
            payment_type: 106,
            cart_id: cartState.id,
            products,
            amount: finalAmount,
            nfce: cuponNumber,
            page_state: {
              setLoadingQrCode,
              setPixQrCode,
              setShowPixQrCodeModal,
              setHandleDisabled,
            },
            ...giftbackData,
          }))
        );
      }
      if (
        paymentType === 'Pix' &&
        cartState.store?.payment_gateway === 'mercadopago'
      ) {
        dispatch(
          finishCartLink(prevState => ({
            ...prevState,
            payment_type: 110,
            cart_id: cartState.id,
            products,
            amount: finalAmount,
            nfce: cuponNumber,
            page_state: {
              setLoadingQrCode,
              setPixQrCode,
              setShowPixQrCodeModal,
              setHandleDisabled,
            },
            ...giftbackData,
          }))
        );
      }
      if (
        paymentType === 'Pix' &&
        cartState.store?.payment_gateway === 'cielo'
      ) {
        dispatch(
          finishCartLink(prevState => ({
            ...prevState,
            payment_type: 112,
            cart_id: cartState.id,
            products,
            amount: finalAmount,
            nfce: cuponNumber,
            page_state: {
              setLoadingQrCode,
              setPixQrCode,
              setShowPixQrCodeModal,
              setHandleDisabled,
            },
            ...giftbackData,
          }))
        );
      }
      if (
        paymentType === 'Pix' &&
        cartState.store?.payment_gateway === 'justa'
      ) {
        dispatch(
          finishCartLink(prevState => ({
            ...prevState,
            payment_type: 118,
            cart_id: cartState.id,
            products,
            amount: finalAmount,
            nfce: cuponNumber,
            page_state: {
              setLoadingQrCode,
              setPixQrCode,
              setShowPixQrCodeModal,
              setHandleDisabled,
            },
            ...giftbackData,
          }))
        );
      }
      if (paymentType === 'Money') {
        setFinishCartState(prevState => ({
          ...prevState,
          data: {
            payment_type: 203,
            change: changeInput ? 0 : changeValue,
          },
          amount: finalAmount,
          nfce: cuponNumber,
          cart_id: cartState.id,
          products,
          freight: freightValueAmount,
          return_freight: returnFreightValueAmount,
          default_freight: defaultFreight,
          userFreight: deliveryFleet !== 'RETIRAR' ? freightWillBePayed : 0,
          ...giftbackData,
        }));
      }
      if (paymentType === 'Link') {
        let display_payment = '';

        if (!checkoutMethods[0]) display_payment += 'hide-card';
        if (!checkoutMethods[1]) display_payment += 'hide-pix';
        if (!checkoutMethods[2]) display_payment += 'hide-bnpl';
        if (storeOnlinePaymentLimit !== 0 && total > storeOnlinePaymentLimit)
          display_payment += 'hide-card';

        setFinishCartState(prevState => ({
          ...prevState,
          payment_type: 0,
          amount: finalAmount,
          nfce: cuponNumber,
          display_payment,
          max_installments: selectedInstallment.value || 1,
          cart_id: cartState.id,
          products,
          freight: freightValueAmount,
          return_freight: returnFreightValueAmount,
          default_freight: defaultFreight,
          userFreight: deliveryFleet !== 'RETIRAR' ? freightWillBePayed : 0,
          ...giftbackData,
        }));
      }
    },
    [
      address,
      cartState?.id,
      cartState?.is_for_delivery,
      cartState?.store?.payment_gateway,
      cartState?.store?.has_pix_key,
      cartState?.is_cross,
      dispatch,
      checkoutMethods,
      paymentMethod,
      paymentType,
      useUserAddress,
      total,
      changeInput,
      cuponNumber,
      setFinishCartState,
      freightWillBePayed,
      freightAlreadyPayed,
      freightValueAmount,
      returnFreightValueAmount,
      defaultFreight,
      storeOnlinePaymentLimit,
      changeValue,
      deliveryFleet,
      selectedInstallment,
      isGiftbackActive,
      saldoDisponivel,
      subscriberId,
      subscriberViddyComercial,
      products,
    ]
  );

  const isBtnDisabled = useMemo(() => {
    if (cartState.is_cross && (!products || products.length === 0)) return true;

    if (Number(total || 0) === 0 && Number(freightWillBePayed || 0) === 0)
      return false;

    if (
      paymentType === 'Money' &&
      !changeInput &&
      Number(changeValue) <= Number(total) + Number(freightWillBePayed)
    )
      return true;

    if (!paymentType || handleDisabled) return true;

    if (paymentType === 'Card' && !paymentMethod) return true;

    if (!done && !useUserAddress && !address) return true;

    if (
      paymentType === 'Pago' &&
      (!total || (freightAlreadyPayed === false && !freightWillBePayed))
    )
      return false;

    if (paymentType === 'Link' && !total && !freightWillBePayed) return true;

    return false;
  }, [
    paymentType,
    done,
    useUserAddress,
    address,
    total,
    freightAlreadyPayed,
    changeInput,
    changeValue,
    freightWillBePayed,
    handleDisabled,
    paymentMethod,
    cartState.is_cross,
    products,
  ]);

  // Função para verificar se pelo menos um método está selecionado
  const atLeastOneMethodSelected = methods => methods.some(method => method);

  // Função para atualizar métodos de checkout
  const updateCheckoutMethods = index => {
    const updatedMethods = [...checkoutMethods];
    updatedMethods[index] = !updatedMethods[index];

    if (atLeastOneMethodSelected(updatedMethods)) {
      setCheckoutMethods(updatedMethods);
      setCheckoutMethodsError(null);
    } else {
      setCheckoutMethodsError('Ao menos um método deve estar selecionado.');
    }
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={{
          link_cielo,
          link_pagarme,
          link_mercadopago,
          link_rede,
          link_pagseguro,
        }}
        noValidate
      >
        <Card className="card new-layout mb-0">
          <CardBody className="card-body new-layout px-5">
            <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-4">
              <h2 className="font-size-20">Opções de Pagamento</h2>
            </Row>

            <Row className="w-100 mx-0 mb-0">
              {/* <PaymentsAvailabilityAlerts /> */}
              <Nav className="w-100" pills>
                <Row className="w-100 mx-0">
                  {PAYMENTS_TYPES.filter(type => type.available).length <=
                    0 && (
                    <Alert color="info">
                      Loja selecionada não possui método de pagamento
                      configurado, vendas com retirada em loja só funciona no
                      formato online, consulte um administrador para fazer essa
                      configuração.
                    </Alert>
                  )}
                  {PAYMENTS_TYPES.filter(type => type.available).map(type => (
                    <React.Fragment key={type.id}>
                      <Col lg={type.col_width} className={type.col_padding}>
                        <NavItem>
                          <NavLink
                            ref={type.id === 1 ? tooltip : null}
                            className={`new-layout-nav-pill card d-flex flex-column align-items-center h-fit-content w-100 ${
                              type.checked ? 'checked' : 'unchecked'
                            } font-size-14`}
                            onClick={() => setPaymentType(type.tag)}
                            to="#"
                            // disabled={type.opacity}
                          >
                            {/* <i className={`${type.icon} fs-30 mb-2`} /> */}
                            <span className="text-nowrap">{type.label}</span>
                          </NavLink>
                        </NavItem>
                      </Col>
                    </React.Fragment>
                  ))}
                </Row>
              </Nav>
            </Row>
          </CardBody>
        </Card>
        {paymentType && (
          <>
            {cartState.is_cross && (
              <Row className="w-100 mx-0 my-2">
                <Col lg={12} className="px-0 py-2">
                  <CartProducts
                    setProductsTotal={updateTotal}
                    setProducts={setProducts}
                  />
                </Col>
              </Row>
            )}
            <Row className="w-100 mx-0 my-2">
              {subscriberId !== subscriberViddyComercial && (
                <Col lg={6} className="px-0 py-2 pr-lg-2">
                  <Card className="card new-layout h-100">
                    <CardBody className="card-body new-layout pb-0 pt-4 px-5">
                      <h2 className="font-size-20">
                        {
                          PAYMENTS_TYPES.find(({ tag }) => tag === paymentType)
                            ?.label
                        }
                      </h2>
                      <Row className="w-100 mx-0 w-100 mx-0 d-flex flex-row align-items-center">
                        {!cartState.is_cross && (
                          <Col xs={12} sm={12} className="px-0">
                            <FormGroup className="w-100 d-flex mb-1 flex-column">
                              <Label>Valor total da compra</Label>
                              <IntlCurrencyInput
                                inputmode="decimal"
                                currency="BRL"
                                name="finalValue"
                                id="finalValue"
                                onChange={(_, floatValue) => {
                                  setTotal(floatValue);
                                  if (floatValue === 0) {
                                    setErrorMessage(
                                      'Para acrescentar o valor do giftback, insira um valor de compra.'
                                    );
                                  } else {
                                    setErrorMessage('');
                                  }

                                  const totalWithFreight =
                                    floatValue +
                                    parseFloat(freightWillBePayed) -
                                    (isGiftbackActive ? saldoDisponivel : 0);

                                  const minInstallmentAmount =
                                    cartState?.store
                                      ?.installment_limit_amount || 0;
                                  const maxStoreInstallments =
                                    cartState?.store?.maximum_installments ||
                                    12; // Novo limite

                                  let maxInstallmentsToUse = 1;

                                  if (
                                    minInstallmentAmount > 0 &&
                                    totalWithFreight > 0
                                  ) {
                                    maxInstallmentsToUse = Math.floor(
                                      totalWithFreight / minInstallmentAmount
                                    );
                                  }

                                  // Agora limitamos o número máximo de parcelas para respeitar o máximo configurado na loja
                                  const finalMaxInstallments = Math.min(
                                    maxInstallmentsToUse,
                                    maxStoreInstallments,
                                    12
                                  ); // Garantindo que nunca ultrapasse 12 parcelas

                                  setMaxInstallments(finalMaxInstallments);
                                }}
                                placeholder="Valor Unitário"
                                className={`dc-discount-input input-color form-control ${
                                  totalValueError ? 'border-danger' : ''
                                }`}
                                value={Number(total)}
                                onFocus={() => setTotalValueError(null)}
                                config={{
                                  locale: 'pt-BR',
                                  formats: {
                                    number: {
                                      BRL: {
                                        style: 'currency',
                                        currency: 'BRL',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      },
                                    },
                                  },
                                }}
                                onBlur={e => {
                                  if (!changeInput) {
                                    setChangeErrorMessage(null);
                                    formRef.current.setFieldError('change', '');
                                    return;
                                  }

                                  if (
                                    formatPriceToNumber(e.target.value) +
                                      freightWillBePayed <=
                                    changeValue
                                  ) {
                                    setChangeErrorMessage(null);
                                    return;
                                  }
                                  setChangeErrorMessage(
                                    'Para que haja troco, o valor inserido deve ser maior que o valor da compra.'
                                  );
                                  formRef.current.setFieldError(
                                    'change',
                                    'Para que haja troco, o valor inserido deve ser maior que o valor da compra.'
                                  );
                                }}
                              />
                              {totalValueError ? (
                                <span className="text-danger">
                                  {totalValueError}
                                </span>
                              ) : null}
                              {paymentType === 'Link' &&
                                storeOnlinePaymentLimit !== 0 &&
                                parseFloat(total) +
                                  parseFloat(freightWillBePayed) >
                                  storeOnlinePaymentLimit && (
                                  <Alert color="info" className="mt-4 mb-0">
                                    O valor desta venda ultrapassa o limite para
                                    pagamento on-line, desse modo, apenas a
                                    venda através do PIX será liberada para o
                                    cliente, entre em contato com o gerente para
                                    aumentar este limite.
                                  </Alert>
                                )}
                            </FormGroup>
                          </Col>
                        )}

                        {paymentType === 'Link' &&
                          cartState.is_cross &&
                          storeOnlinePaymentLimit !== 0 &&
                          parseFloat(total) + parseFloat(freightWillBePayed) >
                            storeOnlinePaymentLimit && (
                            <Alert color="info" className="mt-4 mb-0">
                              O valor desta venda ultrapassa o limite para
                              pagamento on-line, desse modo, apenas a venda
                              através do PIX será liberada para o cliente, entre
                              em contato com o gerente para aumentar este
                              limite.
                            </Alert>
                          )}

                        {paymentType === 'Card' && (
                          <Col className="px-0 mt-3">
                            <Label>Forma de Pagamento</Label>

                            <Row>
                              <Col xs={4}>
                                <CustomInput
                                  type="radio"
                                  id="checkout-methods-1"
                                  name="paymentModality"
                                  className="text-primary z-index-0"
                                  label="Crédito"
                                  checked={paymentMethod === 'Credit'}
                                  onChange={() => setPaymentMethod('Credit')}
                                />
                              </Col>
                              <Col>
                                <CustomInput
                                  type="radio"
                                  id="checkout-methods-2"
                                  name="paymentModality"
                                  className="text-primary z-index-0"
                                  label="Débito"
                                  checked={paymentMethod === 'Debit'}
                                  onChange={() => setPaymentMethod('Debit')}
                                />
                              </Col>
                              <Col>
                                <CustomInput
                                  type="radio"
                                  id="checkout-methods-3"
                                  name="paymentModality"
                                  className="text-primary z-index-0"
                                  label="Pix"
                                  checked={paymentMethod === 'Pix'}
                                  onChange={() => setPaymentMethod('Pix')}
                                />
                              </Col>
                            </Row>
                          </Col>
                        )}

                        {(paymentType === 'Link' ||
                          (paymentType === 'Card' &&
                            paymentMethod === 'Credit')) && (
                          <Col xs={12} sm={12} className="px-0 mb-1 mt-2">
                            {!(
                              paymentType === 'Link' &&
                              storeOnlinePaymentLimit !== 0 &&
                              parseFloat(total) +
                                parseFloat(freightWillBePayed) >
                                storeOnlinePaymentLimit
                            ) &&
                              (checkoutMethods[0] || checkoutMethods[2]) && (
                                <>
                                  <Label>Quantidade de Parcelas</Label>
                                  <i
                                    id="max-installments-tooltip"
                                    className="las la-info-circle font-size-18 font-size-16 text-secondary mb-2 ml-1 h-fit-content"
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="max-installments-tooltip"
                                  >
                                    Esse é o número máximo de parcelas em que o
                                    pagamento via Link de Pagamento poderá ser
                                    efetuado.
                                  </UncontrolledTooltip>
                                  <InputSelect
                                    name="installments"
                                    className="common-select"
                                    options={installmentsOptions}
                                    value={selectedInstallment}
                                    onChange={selectedOption => {
                                      setSelectedInstallment(selectedOption);
                                    }}
                                    placeholder="Quantidade de parcelas"
                                    singlePath
                                  />
                                </>
                              )}
                          </Col>
                        )}

                        {(cartState?.store?.payment_gateway ||
                          cartState?.store?.bnpl_payment_gateway) &&
                          !(
                            storeOnlinePaymentLimit !== 0 &&
                            total > storeOnlinePaymentLimit
                          ) &&
                          paymentType === 'Link' && (
                            <>
                              <Col xs={12} className="px-0 mb-0 mt-3">
                                <Label>Link de Pagamento</Label>
                              </Col>

                              <Row
                                className="mx-0 px-1 mb-1 d-flex"
                                style={{ gap: '1.5rem' }}
                              >
                                {cartState?.store?.payment_gateway && (
                                  <CustomInput
                                    type="checkbox"
                                    id="credit-checkout-method"
                                    name="creditCheckoutMethod"
                                    className="text-primary z-index-0"
                                    label="Crédito"
                                    checked={checkoutMethods[0]}
                                    onChange={() => updateCheckoutMethods(0)}
                                  />
                                )}
                                {cartState?.store?.payment_gateway && (
                                  <CustomInput
                                    type="checkbox"
                                    id="pix-checkout-method"
                                    name="pixCheckoutMethod"
                                    className="text-primary z-index-0"
                                    label="Pix"
                                    checked={checkoutMethods[1]}
                                    onChange={() => updateCheckoutMethods(1)}
                                  />
                                )}
                                {cartState?.store?.bnpl_payment_gateway && (
                                  <CustomInput
                                    type="checkbox"
                                    id="bnpl-checkout-method"
                                    name="bnplCheckoutMethod"
                                    className="text-primary z-index-0"
                                    label="Pix Parcelado"
                                    checked={checkoutMethods[2]}
                                    onChange={() => updateCheckoutMethods(2)}
                                  />
                                )}
                              </Row>
                              {checkoutMethodsError && (
                                <Row className="mx-0 text-danger">
                                  <Alert color="danger" className="mt-2 mb-0">
                                    {checkoutMethodsError}
                                  </Alert>
                                </Row>
                              )}
                            </>
                          )}

                        {paymentType === 'Money' && (
                          <Row className="w-100 mt-3 mx-0 mb-1 justify-content-end align-items-center flex-column flex-md-row">
                            <Col
                              xs={12}
                              className="px-0 d-flex justify-content-between"
                            >
                              <Label className="text-nowrap">
                                Troco para quanto?
                              </Label>
                              <CustomInput
                                type="switch"
                                id="changeInput"
                                name="changeInput"
                                label="Sem Troco"
                                checked={changeInput}
                                className="text-primary float-right"
                                onChange={handleNoChangeClick}
                              />
                            </Col>
                            <Col xs={12} className="px-0">
                              <IntlCurrencyInput
                                inputmode="decimal"
                                name="change"
                                disabled={changeInput}
                                currency="BRL"
                                className={`dc-discount-input input-color form-control ${
                                  !changeInput && changeErrorMessage
                                    ? 'border-danger'
                                    : ''
                                }`}
                                value={parseFloat(changeValue)}
                                onChange={(_e, floatValue) => {
                                  setChangeValue(floatValue);
                                }}
                                onFocus={() => {
                                  setChangeErrorMessage(null);
                                }}
                                config={{
                                  locale: 'pt-BR',
                                  formats: {
                                    number: {
                                      BRL: {
                                        style: 'currency',
                                        currency: 'BRL',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      },
                                    },
                                  },
                                }}
                                /*
                                onChange={(_, floatValue) =>
                                  setChangeInputValue(floatValue)
                                }
                                value={changeInputValue}
                              */
                                onBlur={(_e, floatValue) => {
                                  if (
                                    floatValue >
                                    total + Number(freightWillBePayed)
                                  )
                                    return;
                                  setChangeErrorMessage(
                                    'Para que haja troco, o valor inserido deve ser maior que o valor da compra.'
                                  );
                                  formRef.current.setFieldError(
                                    'change',
                                    'Para que haja troco, o valor inserido deve ser maior que o valor da compra.'
                                  );
                                }}
                              />
                              {!changeInput && changeErrorMessage ? (
                                <span className="text-danger">
                                  {changeErrorMessage}
                                </span>
                              ) : null}
                            </Col>
                          </Row>
                        )}
                        {paymentType === 'Pago' && cartState?.is_cross && (
                          <Col xs={12} sm={12} className="px-0">
                            <FormGroup>
                              <Alert color="info" className="mt-4 mb-0">
                                Atenção: Para finalizar o pedido como Pago, é
                                necessário cadastrar os produtos. O valor total
                                será calculado com base nos itens adicionados.
                              </Alert>
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}
              {cartState?.is_for_delivery && (
                <Col
                  lg={6}
                  className={`px-0 py-2 ${
                    subscriberId === subscriberViddyComercial
                      ? 'pl-lg-0'
                      : 'pl-lg-2'
                  }`}
                >
                  <Card className="card new-layout h-100">
                    <CardBody className="card-body new-layout pb-0 pt-4 px-5">
                      <h2 className="font-size-20">Frete</h2>

                      {/* paymentType === 'Pago' && (
                        <>
                          <Label>O frete já foi pago?</Label>

                          <Row className="px-1 mb-4">
                            <CustomInput
                              type="radio"
                              id="freight-already-payed1"
                              name="freightAlreadyPayed"
                              className="text-primary z-index-0 ml-2"
                              label="Sim"
                              checked={freightAlreadyPayed}
                              onChange={() => setFreightAlreadyPayed(true)}
                              defaultChecked
                            />
                            <CustomInput
                              type="radio"
                              id="freight-already-payed2"
                              name="freightAlreadyPayed"
                              className="text-primary z-index-0 ml-4"
                              label="Não"
                              checked={freightAlreadyPayed === false}
                              onChange={() => setFreightAlreadyPayed(false)}
                            />
                          </Row>
                        </>
                      ) */}
                      <Row className="w-100 mx-0 d-flex flex-row align-items-center mb-3">
                        <Label className="mb-0">Custo da entrega</Label>
                        <b className="ml-2 font-size-14">
                          {freightValueAmount !== null || freightValueAmount > 0
                            ? formatPrice(freightValueAmount)
                            : 'Calculando...'}
                        </b>
                        {freightValueAmount === 0 &&
                          subscriberId === subscriberViddyComercial && (
                            <Alert color="danger" className="mt-4 mb-0">
                              Valor de frete não identificado, pergunte ao
                              suporte qual seria o custo desta entrega enquanto
                              resolvemos este problema.
                            </Alert>
                          )}
                        {freightValueAmount === 0 &&
                          subscriberId !== subscriberViddyComercial && (
                            <Alert color="warning" className="mt-2 mb-0">
                              Valor de frete não identificado, pergunte ao{' '}
                              <a
                                href={`https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                                  '5511935354434'
                                )}&text=Ol%C3%A1%2C%20preciso%20de%20ajuda%2C%20o%20custo%20da%20entrega%20est%C3%A1%20com%20valor%200%2C00%2C%20consegue%20me%20ajudar%3F`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                suporte
                              </a>{' '}
                              qual seria o custo desta entrega enquanto
                              resolvemos este problema.
                            </Alert>
                          )}
                      </Row>
                      <Row className="w-100 mx-0 d-flex flex-row align-items-center mb-1">
                        {paymentType === 'Pago' ? (
                          <Label>Frete pago pelo cliente</Label>
                        ) : (
                          <Label>Frete a ser cobrado do cliente</Label>
                        )}
                        <IntlCurrencyInput
                          inputmode="decimal"
                          disabled={
                            defaultFreight > 0 ||
                            subscriberId === subscriberViddyComercial ||
                            ((subscriberId === subscriberNomura ||
                              subscriberId === subscriberNomuraShoes) &&
                              (cartState?.delivery_method === 'CORREIOS' ||
                                cartState?.delivery_method === 'LALAMOVE'))
                          }
                          currency="BRL"
                          name="freightValue"
                          id="freightValue"
                          onChange={(_, floatValue) => {
                            setFreightWillBePayed(floatValue);
                          }}
                          placeholder="Valor Unitário"
                          className="dc-discount-input input-color form-control"
                          value={
                            subscriberId === subscriberViddyComercial
                              ? parseFloat(freightWillBePayed) * 1.16
                              : parseFloat(freightWillBePayed)
                          }
                          config={{
                            locale: 'pt-BR',
                            formats: {
                              number: {
                                BRL: {
                                  style: 'currency',
                                  currency: 'BRL',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              },
                            },
                          }}
                          onBlur={(_e, floatValue) => {
                            if (floatValue + total <= changeValue) {
                              setChangeErrorMessage(null);
                              return;
                            }
                            setChangeErrorMessage(
                              'Para que haja troco, o valor inserido deve ser maior que o valor da compra.'
                            );
                            formRef.current.setFieldError(
                              'change',
                              'Para que haja troco, o valor inserido deve ser maior que o valor da compra.'
                            );
                          }}
                        />
                        {defaultFreight > 0 &&
                          !['CORREIOS', 'LALAMOVE'].includes(
                            cartState.delivery_method.trim()
                          ) && (
                            <Alert color="info" className="mt-4 mb-0">
                              Valor de frete padrão configurado pela loja, para
                              alterar, entre em contato com o administrador.
                            </Alert>
                          )}
                        {(subscriberId === subscriberNomura ||
                          subscriberId === subscriberNomuraShoes) &&
                          cartState.delivery_method === 'CORREIOS' && (
                            <Alert color="info" className="mt-4 mb-0">
                              O valor de frete foi definido automaticamente como
                              R$ 30,00 devido à seleção da opção de entrega
                              (Correios).
                            </Alert>
                          )}
                        {(subscriberId === subscriberNomura ||
                          subscriberId === subscriberNomuraShoes) &&
                          cartState.delivery_method === 'LALAMOVE' && (
                            <Alert color="info" className="mt-4 mb-0">
                              O valor de frete foi definido automaticamente como
                              R$ 20,00 devido à seleção da opção de entrega
                              (Lalamove).
                            </Alert>
                          )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}

              {cartState?.store?.has_crmbonus_key &&
                paymentType === 'Link' &&
                subscriberId !== subscriberViddyComercial && (
                  <Col lg={6} className="px-0 py-2 pr-lg-2">
                    <Card className="card new-layout h-100">
                      <CardBody className="card-body new-layout pb-0 pt-4 px-5">
                        <GiftbackCard
                          saldoDisponivel={saldoDisponivel}
                          saldoTotal={saldoTotal}
                          dataExpiracao={dataExpiracao}
                          valorMinimoResgate={valorMinimoResgate}
                          valorCompra={Number(total)}
                          errorMessage={errorMessage}
                          setErrorMessage={setErrorMessage}
                          isLoadingGiftback={isLoadingGiftback}
                          isGiftbackActive={isGiftbackActive}
                          onSwitchChange={handleGiftbackSwitchChange}
                          setTotal={setTotal}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                )}

              {/* <Col lg={6} className="px-0 py-2 pr-lg-2">
                <Card className="card new-layout">
                  <CardBody className="card-body new-layout py-4 px-5 py-lg-2 px-lg-4">
                    <Row className="w-100 mx-0 d-flex flex-column flex-lg-row align-items-lg-center flex-nowrap">
                      <Label>
                        Número da nota{' '}
                        <span className="font-size-12 font-weight-500 text-gray-500 ml-lg-2">
                          (Opcional)
                        </span>
                      </Label>
                      <Input
                        name="receipt_number"
                        value={cuponNumber}
                        onChange={e => setCuponNumber(e.target.value)}
                        type="text"
                        placeholder="Número da nota"
                        className="form-control ml-lg-2"
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col> */}
              <Col lg={12} className="px-0 py-2">
                <Card className="card new-layout">
                  <CardBody
                    className="card-body new-layout py-4 px-4"
                    style={{ backgroundColor: '#E8E8E8' }}
                  >
                    <Col>
                      <Row className="w-100 mx-0 d-flex flex-row justify-content-center align-items-center flex-nowrap">
                        <Label className="ml-3 mb-0">
                          Valor total do pedido
                        </Label>
                        <b className="ml-2 font-size-14">
                          {freightValueAmount !== null ||
                          (returnFreightValueAmount !== null && total !== null)
                            ? formatPrice(
                                Number(freightWillBePayed || 0) +
                                  Number(returnFreightValueAmount || 0) +
                                  Number(total || 0) -
                                  (isGiftbackActive ? saldoDisponivel : 0)
                              )
                            : 'Calculando...'}
                        </b>
                      </Row>
                      {/*   {!changeInput && changeInputValue ? (
                        <Row className="w-100 mx-0 mt-2 d-flex flex-row justify-content-center align-items-center flex-nowrap">
                          <Label className="ml-3 mb-0">Valor do troco</Label>
                          <b className="ml-2 font-size-14">
                            {freightValueAmount !== null
                              ? formatPrice(
                                  changeInputValue -
                                    (Number(freightWillBePayed || 0) +
                                      Number(total || 0))
                                )
                              : 'Calculando...'}
                          </b>
                        </Row>
                      ) : null} */}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0" align="end">
                <Button
                  color="primary"
                  size="lg"
                  type="button"
                  className="float-right w-lg-25 new-layout-btn main"
                  disabled={isBtnDisabled}
                  onClick={handleFinishOrder}
                >
                  Avançar
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
      <PixQrCodeModal
        show={showPixQrCodeModal}
        loading={loadingQrCode}
        qrCode={pixQrCode}
      />
    </>
  );
};

export default Payment;
