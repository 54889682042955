export const errorMapper = {
  '0000':
    'Erro #0000 - Pagamento não processado, tente novamente. Se persistir, fale com o nosso suporte.',
  82: 'Erro #82 - Pagamento não processado, tente novamente. Se persistir, fale com o nosso suporte.',
  83: 'Erro #83 - Pagamento não processado, o banco recusou o seu pagamento, verifique com seu banco ou utilize outro cartão.',
  1000: 'Erro #1000 - Pagamento não processado, o banco não aceitou a transação.',
  1001: 'Erro #1001 - Pagamento não processado por erro na data de vencimento do cartão ou cartão vencido.',
  1002: 'Erro #1002 - Seu pagamento ainda está em processamento, confirme com a vendedora os próximos passos da sua compra.',
  1003: 'Erro #1003 - Seu pagamento ainda está em processamento, confirme com a vendedora os próximos passos da sua compra.',
  1004: 'Erro #1004 - Pagamento não processado, verifique se o cartão utilizado pode fazer transações online com o seu banco ou certifique que colocou os dados corretos.',
  1005: 'Erro #1005 - Seu pagamento ainda está em processamento, confirme com a vendedora os próximos passos da sua compra.',
  1007: 'Erro #1007 - Seu pagamento ainda está em processamento, confirme com a vendedora os próximos passos da sua compra.',
  1008: 'Erro #1008 - Pagamento não processado, o seu Banco não autorizou a transação ou certifique que colocou os dados corretos.',
  1009: 'Erro #1009 - O sistema de pagamento da loja está fora do ar, entre em contato com o seu vendedor.',
  1011: 'Erro #1011 - Pagamento não processado por erro na digitação do número do cartão.',
  1014: 'Erro #1011 - Pagamento não processado, tente novamente. Se persistir, fale com o nosso suporte.',
  1015: 'Erro #1011 - Pagamento não processado, tente novamente. Se persistir, fale com o nosso suporte.',
  1016: 'Erro #1016 - Pagamento não processado, o seu banco acusou saldo insuficiente ou certifique que colocou os dados corretos.',
  1018: 'Erro #1018 - Pagamento não processado, o seu banco não reconheceu o seu cartão ou certifique que colocou os dados corretos.',
  1019: 'Erro #1019 - Seu pagamento ainda está em processamento, confirme com a vendedora os próximos passos da sua compra.',
  1021: 'Erro #1021 - Pagamento não processado, o seu banco acusou saldo insuficiente.',
  1025: 'Erro #1025 - Pagamento não processado, o banco informou que o cartão está desabilitado ou certifique que colocou os dados corretos.',
  1029: 'Erro #1029 - Seu pagamento ainda está em processamento, confirme com a vendedora os próximos passos da sua compra.',
  1032: 'Erro #1032 - Seu pagamento não foi processado, pode ser dados incorretos ou o cartão está bloqueado, verifique com o vendedor os próximos passos para a sua compra.',
  1035: 'Erro #1011 - Pagamento não processado por erro no cartão. Se persistir, fale com o nosso suporte.',
  1040: 'Erro #1040 - Pagamento não processado, o seu banco acusou saldo insuficiente ou certifique que colocou os dados corretos.',
  1045: 'Erro #1045 - Pagamento não processado por erro no código de segurança (CVV)',
  1049: 'Erro #1049 - Pagamento não processado, não foi possível contatar com o Banco/Cartão ou certifique que colocou os dados corretos.',
  1050: 'Erro #1050 - Pagamento não processado, não foi possível contatar com o Banco/Cartão ou certifique que colocou os dados corretos.',
  1051: 'Erro #1051 - O sistema de pagamento da loja está fora do ar, entre em contato com o seu vendedor.',
  1052: 'Erro #1052 - O sistema de pagamento da loja está fora do ar, entre em contato com o seu vendedor.',
  1053: 'Erro #1053 - O sistema de pagamento da loja está fora do ar, entre em contato com o seu vendedor.',
  1054: 'Erro #1054 - O sistema de pagamento da loja está fora do ar, entre em contato com o seu vendedor.',
  1055: 'Erro #1055 - O sistema de pagamento da loja está fora do ar, entre em contato com o seu vendedor.',
  1056: 'Erro #1056 - O sistema de pagamento da loja está fora do ar, entre em contato com o seu vendedor.',
  2000: 'Erro #2000 - Seu pagamento ainda está em processamento, confirme com a vendedora os próximos passos da sua compra.',
  2001: 'Erro #2001 - Pagamento não processado por erro na data de vencimento do cartão ou cartão vencido.',
  2002: 'Erro #2002 - Seu pagamento ainda está em processamento, confirme com a vendedora os próximos passos da sua compra.',
  2004: 'Erro #2004 - Pagamento não processado, o banco informou que o seu cartão pode estar com uso restrito para compras online ou certifique que colocou os dados corretos.',
  2007: 'Erro #2007 - Pagamento não processado, o banco informou que o seu cartão pode estar com uso restrito para compras online ou certifique que colocou os dados corretos.',
  2008: 'Erro #2008 - Seu pagamento não foi processado, verifique com o vendedor os próximos passos para a sua compra.',
  2009: 'Erro #2009 - Seu pagamento não foi processado, verifique com o vendedor os próximos passos para a sua compra.',
  2010: 'Erro #2010 - Seu pagamento ainda está em processamento, confirme com a vendedora os próximos passos da sua compra.',
  5000: 'Erro #5000 - Pagamento não processado, tente novamente. Se persistir, fale com o nosso suporte.',
  5001: 'Erro #5000 - Pagamento não processado, tente novamente. Se persistir, fale com o nosso suporte.',
  5006: 'Erro #5000 - Pagamento não processado, tente novamente. Se persistir, fale com o nosso suporte.',
  5025: 'Erro #5025 - Pagamento não processado por erro no código de segurança (CVV)',
  5086: 'Erro #5086 - Pagamento não processado, o banco não aceitou a transação. ',
  5087: 'Erro #5087 - Pagamento não processado, o seu banco acusou limite diário excedido ou certifique que colocou os dados corretos.',
  5088: 'Erro #5088 - Pagamento não processado, o banco não aceitou a transação (amex).',
  5089: 'Erro #5000 - Pagamento não processado, tente novamente. Se persistir, fale com o nosso suporte.',
  5093: 'Erro #5093 - Pagamento não processado, o banco não aceitou a transação ou certifique que colocou os dados corretos.',
  5095: 'Erro #5000 - Pagamento não processado, tente novamente. Se persistir, fale com o nosso suporte.',
  5097: 'Erro #5000 - Pagamento não processado, tente novamente. Se persistir, fale com o nosso suporte.',
  9102: 'Erro #9102 - Pagamento não processado, o banco não aceitou a transação ou certifique que colocou os dados corretos.',
  9103: 'Erro #9103 - Pagamento não processado, tente novamente. Se persistir, fale com o nosso suporte.',
  9108: 'Erro #5000 - Pagamento não processado, tente novamente. Se persistir, fale com o nosso suporte.',
  9109: 'Erro #1049 - Pagamento não processado, não foi possível contatar com o Banco/Cartão ou certifique que colocou os dados corretos.',
  9124: 'Erro #9124 - Pagamento não processado por erro no código de segurança (CVV)',
  9200: 'Erro #9124 - Pagamento não processado por código irreversível, tente utilizar outro cartão.',
  9201: 'Erro #9124 - Pagamento não processado por que o número de tentativas foi excedido.',
  9021: 'Erro #9021 - Pagamento recusado por excesso de tentativas.',
  '-': 'Pagamento não processado, tente novamente. Se persistir, fale com o nosso suporte.',
  IMSG: 'Erro #IMSG - Pagamento não processado, tente novamente. Se persistir, fale com o nosso suporte.',
  N7: 'Erro #N7 - Pagamento não processado, tente novamente. Se persistir, fale com o nosso suporte.',
  '#': 'Pagamento não processado, verifique com o vendedor os próximos passos para a sua compra.',
};

const DEFAULT_ERROR_MESSAGE =
  'Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte.';

/*
export const formatCheckoutError = errorMessage => {
  if (!errorMessage || typeof errorMessage !== 'string') {
    return DEFAULT_ERROR_MESSAGE;
  }

  const errorStatus = errorMessage.slice(1, 5);

  return errorMapper[errorStatus] || DEFAULT_ERROR_MESSAGE;
};
*/

export const formatCheckoutError = errorMessage => {
  if (!errorMessage || typeof errorMessage !== 'string') {
    return { message: DEFAULT_ERROR_MESSAGE, code: 'UNKNOWN_ERROR' };
  }

  const errorStatus = errorMessage.slice(1, 5);

  if (errorMapper[errorStatus]) {
    return { message: errorMapper[errorStatus], code: errorStatus };
  }

  return { message: DEFAULT_ERROR_MESSAGE, code: 'UNKNOWN_ERROR' };
};
