import { useCallback, useMemo, useState } from 'react';
import { FaRegStar, FaStar } from 'react-icons/fa';
import { Button, Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';
import { CANCELLED, DELIVERED, SEARCHING } from '~/util/deliveryStatuses';
import { capitalize, formatPhone, formatPrice } from '~/util/format';
import useRoles from '~/hooks/useRoles';
import api from '~/services/api';
import history from '~/services/history';
import { getRole } from '~/util/getRole';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';

export const defineDeliveryStatusColor = status => {
  if (status === 'Buscando entregador') {
    return 'text-warning';
  }
  if (status === 'A caminho do cliente') {
    return 'text-authorized';
  }
  if (status === 'Entregador na casa do cliente') {
    return 'text-authorized';
  }
  if (status === 'A caminho da loja') {
    return 'text-authorized';
  }
  if (status === 'Entregador na loja') {
    return 'text-authorized';
  }
  if (status === 'Entregue') {
    return 'text-success';
  }
  if (status === 'Entrega cancelada') {
    return 'text-danger';
  }
  if (status === SEARCHING) {
    return 'text-warning';
  }
  if (status === DELIVERED) {
    return 'text-success';
  }
  if (status === CANCELLED) {
    return 'text-danger';
  }
  return 'text-warning';
};

export const PaymentInfoCard = ({ cart }) => {
  const [expanded, setExpanded] = useState(window.innerWidth > 768);

  const roles = useSelector(({ user }) => user.profile.roles);

  const role = useMemo(
    () =>
      getRole(roles, [
        'administrator',
        'digital-consultant',
        'cross-manager',
        'store-manager',
      ]),
    [roles]
  );

  const isLinkPayment =
    (cart?.payment_type === 105 ||
      cart?.payment_type === 106 ||
      cart?.payment_type === 110 ||
      cart?.payment_type === 112 ||
      cart?.payment_type === 114 ||
      cart?.payment_type === 118) &&
    cart?.status >= 1 &&
    cart?.payment_process?.qrcode;

  const isBnplPayment = [115, 116].includes(cart?.payment_type);

  const { isAdmin, isStoreManager, isSupport } = useRoles();

  const showReprocessBtn = useMemo(() => {
    if (
      cart?.status === 0 &&
      cart.status_text === 'Pagamento rejeitado pelo anti-fraude' &&
      cart.store.payment_gateway === 'cielo' &&
      (isAdmin || isStoreManager)
    ) {
      return true;
    }
    return false;
  }, [cart, isAdmin, isStoreManager]);

  const handleReprocessor = useCallback(async () => {
    try {
      await api.patch(`${role}/carts/${cart.id}/payment/force`);
      history.push('/');
      toast.success('Pagamento reprocessado com sucesso!');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  }, [cart, role]);

  const handleChangeback = useCallback(async () => {
    try {
      await api.patch(`${role}/carts/${cart.id}/payment/cancel`);
      history.push('/');
      toast.success('Pagamento estornado com sucesso!');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  }, [cart, role]);

  if (cart?.is_suitcase_delivery && cart?.status <= 8) return null;

  if (
    (!cart?.is_suitcase_delivery &&
      (cart?.status === 1 ||
        (cart?.payment_type !== 201 &&
          cart?.payment_type !== 202 &&
          cart?.payment_type !== 203 &&
          cart?.payment_type !== 204 &&
          cart?.payment_type !== 401 &&
          !['Paid', 'canceled'].includes(cart.payment_process?.status) &&
          !cart?.payment_process?.transaction_id))) ||
    (cart?.payment_process?.status === 'failed' &&
      !cart.status_text === 'Pagamento rejeitado pelo anti-fraude')
  )
    return null;

  return (
    <Card className="new-layout card">
      <CardBody className="new-layout card-body px-3 py-3">
        <Row
          className="w-100 mx-0 cursor-pointer"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <Col className="font-size-16 font-weight-600 px-0">
            <Row className="w-100 mx-0 justify-content-between">
              <div className="px-0 mb-0">Pagamento</div>
              <div className="px-0 mb-0">
                <IconButton
                  icon={`las la-${expanded ? 'angle-up' : 'angle-right'}`}
                  click={() => {
                    setExpanded(!expanded);
                  }}
                  color="gray-700"
                />
              </div>
            </Row>
          </Col>
        </Row>
        <Collapse isOpen={expanded}>
          {cart?.payment_type === 401 && (
            <Row className="mx-0 mt-3">
              <Col lg={12} className="px-0 mb-2 mb-lg-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Método
                </div>
                <div className="font-size-14 font-weight-400 text-gray-700">
                  Pago antecipadamente
                </div>
              </Col>
            </Row>
          )}
          {cart?.payment_type === 201 && (
            <Row className="mx-0 mt-3">
              <Col lg={6} className="px-0 mb-2 mb-lg-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Método
                </div>
                <div className="font-size-14 font-weight-400 text-gray-700">
                  Maquineta (Crédito)
                </div>
              </Col>
              <Col lg={6} className="w-100 px-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Parcelas
                </div>
                {cart?.payment_process?.installments ? (
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    {cart?.payment_process?.installments === 1
                      ? 'Pagamento à vista'
                      : `Parcelado em ${cart?.payment_process?.installments}x`}
                  </div>
                ) : (
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    -
                  </div>
                )}
              </Col>
            </Row>
          )}
          {cart?.payment_type === 202 && (
            <Row className="mx-0 mt-3">
              <Col lg={6} className="px-0 mb-2 mb-lg-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Método
                </div>
                <div className="font-size-14 font-weight-400 text-gray-700">
                  Maquineta (Débito)
                </div>
              </Col>
              <Col lg={6} className="w-100 px-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Parcelas
                </div>
                {cart?.payment_process?.installments ? (
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    {cart?.payment_process?.installments === 1
                      ? 'Pagamento à vista'
                      : `Parcelado em ${cart?.payment_process?.installments}x`}
                  </div>
                ) : (
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    -
                  </div>
                )}
              </Col>
            </Row>
          )}
          {cart?.payment_type === 203 && (
            <Row className="mx-0 mt-3">
              <Col lg={6} className="px-0 mb-2 mb-lg-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Método
                </div>
                <div className="font-size-14 font-weight-400 text-gray-700">
                  Dinheiro
                </div>
              </Col>
              <Col xs={12} lg={6} className="w-100 px-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Parcelas
                </div>
                {cart?.payment_process?.installments ? (
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    {cart?.payment_process?.installments === 1
                      ? 'Pagamento à vista'
                      : `Parcelado em ${cart?.payment_process?.installments}x`}
                  </div>
                ) : (
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    -
                  </div>
                )}
              </Col>
              <Col xs={12} lg={6} className="w-100 px-0 mt-3">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Troco para
                </div>
                <div className="font-size-14 font-weight-400 text-gray-700">
                  {Number(cart?.payment_process?.change)
                    ? formatPrice(cart?.payment_process?.change)
                    : '-'}
                </div>
              </Col>
            </Row>
          )}
          {cart?.payment_type === 204 && (
            <Row className="mx-0 mt-3">
              <Col lg={6} className="px-0 mb-2 mb-lg-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Método
                </div>
                <div className="font-size-14 font-weight-400 text-gray-700">
                  Maquineta (Pix)
                </div>
              </Col>
            </Row>
          )}
          {isBnplPayment && (
            <Row className="mx-0 mt-3">
              <Col lg={6} className="px-0 mb-2 mb-lg-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Método
                </div>
                <div className="font-size-14 font-weight-400 text-gray-700">
                  Pix Parcelado
                </div>
              </Col>
              <Col lg={6} className="px-0 mb-2 mb-lg-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Identificador da transação
                </div>
                <div className="font-size-14 font-weight-400 text-gray-700">
                  {cart.payment_process?.transaction_id}
                </div>
              </Col>
              <Col lg={6} className="px-0 mb-2 mb-lg-0 mt-3">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Status
                </div>
                <div className="font-size-14 font-weight-400 text-gray-700">
                  {cart.payment_process?.status === 'Pending' &&
                    cart.payment_process?.reason === 'FirstPayment' &&
                    'Primeiro Pagamento Pendente'}

                  {cart.payment_process?.status === 'Pending' &&
                    cart.payment_process?.reason === 'EmailValidation' &&
                    'Validação Pendente'}

                  {cart.payment_process?.status === 'Pending' &&
                    cart.payment_process?.reason === 'ProviderReview' &&
                    'Aprovação do Fornecedor Pendente'}

                  {cart.payment_process?.status === 'Paid' && 'Pago'}
                </div>
              </Col>
              <Col xs={12} lg={6} className="w-100 px-0 mt-3">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Parcelas
                </div>
                {cart?.payment_process?.installment_option?.installments ? (
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    {cart?.payment_process?.installment_option?.installments ===
                    1
                      ? 'Pagamento à vista'
                      : `Parcelado em ${cart?.payment_process?.installment_option?.installments}x`}
                  </div>
                ) : (
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    -
                  </div>
                )}
              </Col>
            </Row>
          )}
          {isLinkPayment && (
            <Row className="mx-0 mt-3">
              <Col lg={6} className="px-0 mb-2 mb-lg-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Método
                </div>
                <div className="font-size-14 font-weight-400 text-gray-700">
                  Pix
                </div>
              </Col>
              <Col lg={6} className="px-0 mb-2 mb-lg-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Identificador da transação
                </div>
                <div className="font-size-14 font-weight-400 text-gray-700">
                  {cart.payment_process?.transaction_id}
                </div>
              </Col>
            </Row>
          )}
          {(cart?.payment_process?.payment_method === 'credit_card' ||
            (cart?.payment_process?.payment_method !== 'bnpl' &&
              cart?.payment_process?.transaction_id &&
              !cart?.payment_process?.qrcode)) && (
            <>
              <Row className="w-100 mx-0 mt-3 mb-lg-4">
                <Col lg={6} className="px-0 mb-2 mb-lg-0">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    Método
                  </div>
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    Cartão
                  </div>
                </Col>
                <Col lg={6} className="px-0 pl-lg-3 mb-2 mb-lg-0">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    Código de autorização
                  </div>
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    {cart.payment_process?.authorization_code || '-'}
                  </div>
                </Col>
              </Row>
              <Row className="w-100 mx-0 mb-lg-4">
                <Col lg={6} className="pl-0 pr-0 mb-2 mb-lg-0">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    NSU
                  </div>
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    {cart?.payment_process?.nsu || '-'}
                  </div>
                </Col>
                <Col lg={6} className="px-0 pl-lg-3 mb-2 mb-lg-0">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    Nome
                  </div>
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    {cart?.payment_process?.card_holder_name || '-'}
                  </div>
                </Col>
              </Row>
              {isSupport && (
                <Row className="w-100 mx-0 mb-lg-4">
                  <Col lg={6} className="pl-0 pr-0 mb-2 mb-lg-0">
                    <div className="font-size-14 font-weight-600 text-gray-600">
                      Últimos digitos
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {cart?.payment_process?.card_last_digits || '-'}
                    </div>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-3 mb-2 mb-lg-0">
                    <div className="font-size-14 font-weight-600 text-gray-600">
                      Bandeira
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {cart?.payment_process?.card_brand || '-'}
                    </div>
                  </Col>
                </Row>
              )}
              {!isSupport && (
                <Row className="w-100 mx-0 mb-lg-4">
                  <Col lg={6} className="px-0 mb-2 mb-lg-0">
                    <div className="font-size-14 font-weight-600 text-gray-600">
                      Bandeira
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {cart?.payment_process?.card_brand || '-'}
                    </div>
                  </Col>
                  <Col lg={6} className="pl-0 pl-lg-3 mb-0 mb-lg-0">
                    <div className="font-size-14 font-weight-600 text-gray-600">
                      Parcelas
                    </div>
                    {cart?.payment_process?.installments ? (
                      <div className="font-size-14 font-weight-400 text-gray-700">
                        {cart?.payment_process?.installments === 1
                          ? 'Pagamento à vista'
                          : `Parcelado em ${cart?.payment_process?.installments}x`}
                      </div>
                    ) : (
                      <div className="font-size-14 font-weight-400 text-gray-700">
                        -
                      </div>
                    )}
                  </Col>
                </Row>
              )}
              {isSupport && (
                <Row className="w-100 mx-0 mb-lg-4">
                  <Col lg={12} className="pl-0 pr-0 mb-0 mb-lg-0">
                    <div className="font-size-14 font-weight-600 text-gray-600">
                      Parcelas
                    </div>
                    {cart?.payment_process?.installments ? (
                      <div className="font-size-14 font-weight-400 text-gray-700">
                        {cart?.payment_process?.installments === 1
                          ? 'Pagamento à vista'
                          : `Parcelado em ${cart?.payment_process?.installments}x`}
                      </div>
                    ) : (
                      <div className="font-size-14 font-weight-400 text-gray-700">
                        -
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </>
          )}
          {showReprocessBtn && (
            <div className="pt-3">
              <Button
                // color="primary"
                className="new-layout-btn main mr-lg-2 mb-2 mb-lg-0"
                onClick={handleReprocessor}
                outline
              >
                <i className="fa fa-spinner pl-1 mr-2 font-size-14" />
                Aprovar
              </Button>

              <Button
                // color="primary"
                className="new-layout-btn main ml-lg-2 mb-2 mb-lg-0"
                onClick={handleChangeback}
                outline
              >
                <i className="fas fa-undo pl-1 mr-2 font-size-14" />
                Estornar
              </Button>
            </div>
          )}
        </Collapse>
      </CardBody>
    </Card>
  );
};

export const BillingInfoCard = ({ cart }) => {
  const [expanded, setExpanded] = useState(window.innerWidth > 768);

  if (cart?.is_suitcase_delivery && cart?.status <= 8) return null;

  if (
    !cart?.is_suitcase_delivery &&
    (cart?.status === 1 ||
      cart?.payment_process?.payment_method !== 'credit_card' ||
      (cart?.payment_process?.payment_method === 'bnpl' &&
        cart?.payment_process?.qrcode) ||
      (cart?.payment_type !== 201 &&
        cart?.payment_type !== 202 &&
        cart?.payment_type !== 203 &&
        cart?.payment_type !== 204 &&
        cart?.payment_type !== 401 &&
        !['Paid', 'canceled'].includes(cart.payment_process?.status) &&
        !cart?.payment_process?.transaction_id))
  )
    return null;

  return (
    <Card className="new-layout card">
      <CardBody className="new-layout card-body px-3 py-3">
        <Row
          className="w-100 mx-0 cursor-pointer"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <Col className="font-size-16 font-weight-600 px-0">
            <Row className="w-100 mx-0 justify-content-between">
              <div className="px-0 mb-0">Dados de Cobrança</div>
              <div className="px-0 mb-0">
                <IconButton
                  icon={`las la-${expanded ? 'angle-up' : 'angle-right'}`}
                  click={() => {
                    setExpanded(!expanded);
                  }}
                  color="gray-700"
                />
              </div>
            </Row>
          </Col>
        </Row>
        <Collapse isOpen={expanded}>
          {(cart?.payment_process?.payment_method === 'credit_card' ||
            (cart?.payment_process?.payment_method !== 'bnpl' &&
              !cart?.payment_process?.qrcode)) && (
            <>
              <Row className="w-100 mx-0 mt-3 mb-lg-4">
                <Col lg={12} className="px-0 mb-2 mb-lg-0">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    Nome
                  </div>
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    {cart.payment_process?.charge?.name || '-'}
                  </div>
                </Col>
              </Row>
              <Row className="w-100 mx-0 mb-lg-4">
                <Col lg={6} className="pl-0 pr-0 mb-2 mb-lg-0">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    E-mail
                  </div>
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    {cart.payment_process?.charge?.email || '-'}
                  </div>
                </Col>
                <Col lg={6} className="pl-0 pr-0 mb-2 mb-lg-0">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    Telefone
                  </div>
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    {cart.payment_process?.charge?.telephone || '-'}
                  </div>
                </Col>
              </Row>
              <Row className="w-100 mx-0 mb-lg-4">
                <Col lg={6} className="pl-0 pr-0 mb-2 mb-lg-0">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    CPF
                  </div>
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    {cart.payment_process?.charge?.cpf || '-'}
                  </div>
                </Col>
                <Col lg={6} className="pl-0 pr-0 mb-2 mb-lg-0">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    Data de Nascimento
                  </div>
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    {cart.payment_process?.charge?.birth_date
                      ? format(
                          parseISO(cart.payment_process.charge.birth_date),
                          'dd/MM/yyyy'
                        )
                      : '-'}
                  </div>
                </Col>
              </Row>
              <Row className="w-100 mx-0 mb-lg-4">
                <Col lg={12} className="pl-0 pr-0 mb-0 mb-lg-0">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    Endereço de Cobrança
                  </div>
                  {cart?.payment_process?.charge?.address ? (
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {cart?.payment_process?.charge?.address.street_name},{' '}
                      {cart?.payment_process?.charge?.address.number} -{' '}
                      {cart?.payment_process?.charge?.address.neighborhood},{' '}
                      {cart?.payment_process?.charge?.address.city} -{' '}
                      {cart?.payment_process?.charge?.address.state}, CEP{' '}
                      {cart?.payment_process?.charge?.address.cep}
                    </div>
                  ) : (
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      -
                    </div>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Collapse>
      </CardBody>
    </Card>
  );
};

export const ColectInfoCard = ({ cart }) => {
  const [expanded, setExpanded] = useState(window.innerWidth > 768);

  if (
    (cart.return_delivery?.status !== DELIVERED && cart?.status < 8) ||
    cart?.status === 13 ||
    cart?.status === 14
  )
    return null;

  if (!cart.is_for_delivery) return null;

  return (
    <Card className="new-layout card">
      <CardBody className="new-layout card-body px-3 py-3">
        <Row
          className="w-100 mx-0 cursor-pointer"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <Col className="font-size-16 font-weight-600 px-0">
            <Row className="w-100 mx-0 justify-content-between">
              <div className="px-0 mb-0">
                {cart?.is_suitcase_delivery ? 'Coleta' : 'Devolução'}
              </div>
              <div className="px-0 mb-0">
                <IconButton
                  icon={`las la-${expanded ? 'angle-up' : 'angle-right'}`}
                  click={() => {
                    setExpanded(!expanded);
                  }}
                  color="gray-700"
                />
              </div>
            </Row>
          </Col>
        </Row>
        <Collapse isOpen={expanded}>
          <Row className="w-100 mx-0 mt-3 mb-lg-4">
            <Col lg={6} className="px-0 mb-2 mb-lg-0">
              <div className="font-size-14 font-weight-600 text-gray-600">
                Endereço de{' '}
                {cart?.is_suitcase_delivery ? 'coleta' : 'devolução'}
              </div>
              <div className="font-size-14 font-weight-400 text-gray-700">
                {cart?.return_delivery_address?.street_name},{' '}
                {cart?.return_delivery_address?.number} -{' '}
                {cart?.return_delivery_address?.neighborhood},{' '}
                {cart?.return_delivery_address?.city} -{' '}
                {cart?.return_delivery_address?.state}, CEP{' '}
                {cart?.return_delivery_address?.cep}
              </div>
            </Col>
            <Col lg={6} className="pl-0 pl-lg-3 pr-0 mb-2 mb-lg-0">
              <div className="font-size-14 font-weight-600 text-gray-600">
                Endereço de entrega
              </div>
              <div className="font-size-14 font-weight-400 text-gray-700">
                {cart?.return_store?.fantasy_name}
              </div>
            </Col>
          </Row>
          <Row className="w-100 mx-0 mb-lg-4">
            <Col xs={6} className="px-0 mb-2 mb-lg-0">
              <div className="font-size-14 font-weight-600 text-gray-600">
                Transportadora
              </div>
              <div className="font-size-14 font-weight-400 text-gray-700">
                {cart.return_delivery_method &&
                  capitalize(cart.return_delivery_method)}
              </div>
            </Col>
            <Col xs={6} className="px-0 pl-lg-3 mb-2 mb-lg-0">
              <div className="font-size-14 font-weight-600 text-gray-600">
                Veículo
              </div>
              <div className="font-size-14 font-weight-400 text-gray-700">
                {cart.return_delivery_vehicle === 'M' ? 'Moto' : 'Carro'}
              </div>
            </Col>
          </Row>
          <Row className="w-100 mx-0 mb-lg-4">
            <Col xs={6} className="pl-0 pr-0 mb-2 mb-lg-0">
              <div className="font-size-14 font-weight-600 text-gray-600">
                Situação da entrega
              </div>
              {cart.return_delivery?.status_text ? (
                <span
                  className={`font-size-14 font-weight-400 ${defineDeliveryStatusColor(
                    cart.return_delivery?.status_text
                  )}`}
                >
                  {cart.return_delivery?.status_text}
                </span>
              ) : (
                <span className="font-size-14 font-weight-400 text-gray-700">
                  -
                </span>
              )}
            </Col>
            <Col xs={6} className="pl-0 pl-lg-3 pr-0 mb-2 mb-lg-0">
              <div className="font-size-14 font-weight-600 text-gray-600">
                Nome do entregador
              </div>
              <div className="font-size-14 font-weight-400 text-gray-700">
                {cart.return_delivery?.worker_name ?? '-'}
              </div>
            </Col>
          </Row>
          <Row className="w-100 mx-0 mb-lg-4">
            <Col xs={6} className="pl-0 pr-0 mb-2 mb-lg-0">
              <div className="font-size-14 font-weight-600 text-gray-600">
                Tel do entregador
              </div>
              <div className="font-size-14 font-weight-400 text-gray-700">
                {cart.return_delivery?.worker_phone
                  ? formatPhone(cart.return_delivery?.worker_phone)
                  : '-'}
              </div>
            </Col>
            {cart?.return_delivery_method === 'UBER' ? (
              <Col xs={6} className="pl-0 pl-lg-3 pr-0 mb-2 mb-lg-4">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Uber ID
                </div>
                <div className="font-size-14 font-weight-400 text-gray-700">
                  {cart.return_delivery?.uber_uuid || '-'}
                </div>
              </Col>
            ) : (
              <Col xs={6} className="pl-0 pl-lg-3 pr-0 mb-2 mb-lg-4">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  ID da entrega
                </div>
                <div className="font-size-14 font-weight-400 text-gray-700">
                  {cart.return_delivery?.id_on_service || '-'}
                </div>
              </Col>
            )}
            {cart?.return_delivery?.finalization_reason && (
              <>
                <Col xs={6} className="px-0 mb-2 mb-lg-0 mb-lg-0">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    Motivo de finalização
                  </div>
                  <span className="font-size-14 font-weight-400">
                    {cart?.return_delivery?.finalization_reason}
                  </span>
                </Col>
              </>
            )}
            {cart?.return_delivery?.finalization_details && (
              <>
                <Col xs={6} className="pl-0 pl-lg-3 pr-0 mb-2 mb-lg-0 mb-lg-0">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    Detalhes da finalização
                  </div>
                  <span className="font-size-14 font-weight-400">
                    {cart?.return_delivery?.finalization_details}
                  </span>
                </Col>
              </>
            )}
          </Row>
          <Row className="w-100 mx-0 mb-lg-4">
            {cart?.status >= 8 && (
              <Col xs={12} lg={6} className="pl-0 pr-0 mb-2 mb-lg-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Avaliação de entrega
                </div>
                {cart?.return_delivery_score ? (
                  <div className="font-size-14 font-weight-400 text-gray-700 mt-1">
                    {cart?.return_delivery_score.score >= 1 ? (
                      <FaStar color="#ffd600" size={30} />
                    ) : (
                      <FaRegStar color="#2544b1" size={30} />
                    )}
                    {cart?.return_delivery_score.score >= 2 ? (
                      <FaStar color="#ffd600" size={30} />
                    ) : (
                      <FaRegStar color="#2544b1" size={30} />
                    )}
                    {cart?.return_delivery_score.score >= 3 ? (
                      <FaStar color="#ffd600" size={30} />
                    ) : (
                      <FaRegStar color="#2544b1" size={30} />
                    )}
                    {cart?.return_delivery_score.score >= 4 ? (
                      <FaStar color="#ffd600" size={30} />
                    ) : (
                      <FaRegStar color="#2544b1" size={30} />
                    )}
                    {cart?.return_delivery_score.score >= 5 ? (
                      <FaStar color="#ffd600" size={30} />
                    ) : (
                      <FaRegStar color="#2544b1" size={30} />
                    )}
                  </div>
                ) : (
                  'Sem avaliação'
                )}
              </Col>
            )}
          </Row>
        </Collapse>
      </CardBody>
    </Card>
  );
};

export const DeliveryInfoCard = ({ cart }) => {
  const [expanded, setExpanded] = useState(window.innerWidth > 768);

  if (
    cart.delivery &&
    cart.delivery?.status !== DELIVERED &&
    cart?.status < 7 &&
    cart?.status !== 6 &&
    cart?.status !== 4
  )
    return null;

  if (!cart.is_for_delivery)
    return (
      <Card className="new-layout card">
        <CardBody className="new-layout card-body px-3 py-3">
          <Row
            className="w-100 mx-0 cursor-pointer"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <Col className="font-size-16 font-weight-600 px-0">
              <Row className="w-100 mx-0 d-flex justify-content-between">
                <div className="px-0 mb-0">Entrega</div>
                <div className="px-0 mb-0">
                  <IconButton
                    icon={`las la-${expanded ? 'angle-up' : 'angle-right'}`}
                    click={() => {
                      setExpanded(!expanded);
                    }}
                    color="gray-700"
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Collapse isOpen={expanded}>
            <Row className="w-100 mx-0 mt-3 mb-0">
              <Col lg={12} className="px-0 mb-lg-0 pb-3">
                <div className="font-size-14 font-weight-600 text-gray-800">
                  Retirada em loja
                </div>
              </Col>
              <Col lg={12} className="px-0 mb-lg-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Loja de retirada
                </div>
                <div className="font-size-14 font-weight-400 text-gray-700">
                  {cart.store.fantasy_name}
                </div>
              </Col>
            </Row>
          </Collapse>
        </CardBody>
      </Card>
    );

  return (
    <Card className="new-layout card">
      <CardBody className="new-layout card-body px-3 py-3">
        <Row
          className="w-100 mx-0 cursor-pointer"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <Col className="font-size-16 font-weight-600 px-0">
            <Row className="w-100 mx-0 d-flex justify-content-between">
              <div className="px-0 mb-0">Entrega</div>
              <div className="px-0 mb-0">
                <IconButton
                  icon={`las la-${expanded ? 'angle-up' : 'angle-right'}`}
                  click={() => {
                    setExpanded(!expanded);
                  }}
                  color="gray-700"
                />
              </div>
            </Row>
          </Col>
        </Row>
        <Collapse isOpen={expanded}>
          <Row className="w-100 mx-0 mt-3 mb-lg-4">
            <Col lg={6} className="px-0 mb-2 mb-lg-0 mb-lg-4">
              <div className="font-size-14 font-weight-600 text-gray-600">
                Endereço de coleta
              </div>
              <div className="font-size-14 font-weight-400 text-gray-700">
                {cart.store.fantasy_name}
              </div>
            </Col>
            <Col lg={6} className="pl-0 pl-lg-3 pr-0 mb-2 mb-lg-0 mb-lg-4">
              <div className="font-size-14 font-weight-600 text-gray-600">
                Endereço de entrega
              </div>
              <div className="font-size-14 font-weight-400 text-gray-700">
                {cart.delivery_address?.full_address || '-'}
              </div>
            </Col>
            <Col xs={6} className="px-0 mb-2 mb-lg-0 mb-lg-4">
              <div className="font-size-14 font-weight-600 text-gray-600">
                Transportadora
              </div>
              <div className="font-size-14 font-weight-400 text-gray-700">
                {capitalize(cart.delivery_method)}
              </div>
            </Col>
            <Col xs={6} className="px-0 mb-2 mb-lg-0 pl-lg-3">
              <div className="font-size-14 font-weight-600 text-gray-600">
                Veículo
              </div>
              <div className="font-size-14 font-weight-400 text-gray-700">
                {cart.delivery_vehicle === 'M' ? 'Moto' : 'Carro'}
              </div>
            </Col>
            <Col xs={6} className="pl-0 pr-0 mb-2 mb-lg-0 mb-lg-4">
              <div className="font-size-14 font-weight-600 text-gray-600">
                Situação da entrega
              </div>
              {cart.delivery?.status_text ? (
                <span
                  className={`font-size-14 font-weight-400 ${defineDeliveryStatusColor(
                    cart.delivery?.status_text
                  )}`}
                >
                  {cart.delivery?.status_text}
                </span>
              ) : (
                <span className="font-size-14 font-weight-400 text-gray-700">
                  {cart?.delivery ? '-' : 'Aguardando envio'}
                </span>
              )}
            </Col>
            {cart.delivery && (
              <>
                <Col xs={6} className="pl-0 pl-lg-3 pr-0 mb-2 mb-lg-4">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    Nome do entregador
                  </div>
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    {cart.delivery?.worker_name ?? '-'}
                  </div>
                </Col>
                <Col xs={6} className="pl-0 pr-0 mb-2 mb-lg-4">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    Tel do entregador
                  </div>
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    {cart.delivery?.worker_phone
                      ? formatPhone(cart.delivery?.worker_phone)
                      : '-'}
                  </div>
                </Col>
              </>
            )}
            {cart?.delivery_method === 'UBER' ? (
              <Col xs={6} className="pl-0 pl-lg-3 pr-0 mb-2 mb-lg-4">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Uber ID
                </div>
                <div className="font-size-14 font-weight-400 text-gray-700">
                  {cart.delivery?.uber_uuid || '-'}
                </div>
              </Col>
            ) : (
              <Col xs={6} className="pl-0 pl-lg-3 pr-0 mb-2 mb-lg-4">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  ID da entrega
                </div>
                <div className="font-size-14 font-weight-400 text-gray-700">
                  {cart.delivery?.id_on_service || '-'}
                </div>
              </Col>
            )}
            {cart?.delivery?.finalization_reason && (
              <>
                <Col xs={6} className="px-0 mb-2 mb-lg-0 mb-lg-0">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    Motivo de finalização
                  </div>
                  <span className="font-size-14 font-weight-400">
                    {cart?.delivery?.finalization_reason}
                  </span>
                </Col>
              </>
            )}
            {cart?.delivery?.finalization_details && (
              <>
                <Col xs={6} className="pl-0 pl-lg-3 pr-0 mb-2 mb-lg-0 mb-lg-0">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    Detalhes da finalização
                  </div>
                  <span className="font-size-14 font-weight-400">
                    {cart?.delivery?.finalization_details}
                  </span>
                </Col>
              </>
            )}
          </Row>
          <Row className="w-100 mx-0 mb-lg-0">
            {(cart?.status >= 7 || cart?.status === 4) && (
              <Col xs={12} lg={6} className="pl-0 pr-0 mb-2 mb-lg-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Avaliação de entrega
                </div>
                {cart?.delivery_score ? (
                  <div className="font-size-14 font-weight-400 text-gray-700 mt-1">
                    {cart?.delivery_score.score >= 1 ? (
                      <FaStar color="#ffd600" size={30} />
                    ) : (
                      <FaRegStar color="#2544b1" size={30} />
                    )}
                    {cart?.delivery_score.score >= 2 ? (
                      <FaStar color="#ffd600" size={30} />
                    ) : (
                      <FaRegStar color="#2544b1" size={30} />
                    )}
                    {cart?.delivery_score.score >= 3 ? (
                      <FaStar color="#ffd600" size={30} />
                    ) : (
                      <FaRegStar color="#2544b1" size={30} />
                    )}
                    {cart?.delivery_score.score >= 4 ? (
                      <FaStar color="#ffd600" size={30} />
                    ) : (
                      <FaRegStar color="#2544b1" size={30} />
                    )}
                    {cart?.delivery_score.score >= 5 ? (
                      <FaStar color="#ffd600" size={30} />
                    ) : (
                      <FaRegStar color="#2544b1" size={30} />
                    )}
                  </div>
                ) : (
                  'Sem avaliação'
                )}
              </Col>
            )}
          </Row>
        </Collapse>
      </CardBody>
    </Card>
  );
};
