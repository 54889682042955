import api from '~/services/api';

export const sendDebugEmail = async (clientDataDebug, cartId, token) => {
  try {
    await api.post(`/shareable-carts/debug-email/${cartId}`, clientDataDebug, {
      headers: { 'X-Shareable-Token': token },
    });
    console.log('E-mail de erro enviado com sucesso.');
  } catch (errorDebug) {
    console.error('Falha ao enviar e-mail de erro:', errorDebug.message);
  }
};
