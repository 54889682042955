import React, { useState, useEffect } from 'react';
import useRoles from '~/hooks/useRoles';
import { Card, CardBody, Row, Col, Label, Input } from 'reactstrap';
import api from '~/services/api';

export default function LossCheckboxCard({ sale, hadLoss }) {
  const [isChecked, setIsChecked] = useState(hadLoss);
  const [loading, setLoading] = useState(false);
  const { isSupport } = useRoles();

  useEffect(() => {
    setIsChecked(hadLoss);
  }, [hadLoss]);

  const handleChange = async () => {
    if (loading) return;

    setLoading(true);

    try {
      await api.patch(`/administrator/carts/${sale.id}`, {
        had_loss: !isChecked,
      });
      setIsChecked(!isChecked);
    } catch (error) {
      console.error('Erro ao atualizar extravio:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!isSupport || !sale.is_for_delivery || !sale.delivery) {
    return null;
  }

  return (
    <Card className="new-layout card mt-3">
      <CardBody className="new-layout card-body px-3 py-3">
        <Row className="w-100 mx-0 align-items-center">
          <Col className="font-size-16 font-weight-600 px-0">Extravio?</Col>
          <Col xs="auto" className="px-0">
            <Label check>
              <Input
                type="checkbox"
                checked={isChecked}
                onChange={handleChange}
                disabled={loading}
              />{' '}
              {loading ? 'Atualizando...' : 'Marcar/Desmarcar'}
            </Label>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
