import { takeLatest, put, all, call, select } from 'redux-saga/effects';

// Toast to send error/success message
import { toast } from 'react-toastify';

// Cart Redux States

// Services instance
import Axios from 'axios';
import api from '~/services/api';
import history from '~/services/history';
import { CreateShortenUrl } from '~/util/shortenUrl';
import { sendDebugEmail } from '~/services/emailService';

import { formatCheckoutError } from '~/pages/SharedCart/NewLayout/checkoutErrorMapper';

import {
  FINALIZE,
  LOAD_CART,
  PATCH_CART,
  SAVE_CLIENT,
  SET_IS_FOR_DELIVERY,
  SET_PAYMENT_TYPE,
  SET_PIX_DATA,
  UPDATE_CLIENT,
} from './actionTypes';
// eslint-disable-next-line no-unused-vars
import {
  patchCart,
  setActiveTab,
  setCart,
  setCieloAuthURL,
  setClient,
  setFinishedSuccessfully,
  setIp,
  setPixDataSuccess,
  setShowCieloModal,
  updateCart,
} from './actions';

function* LoadCart({ payload: { id, token } }) {
  try {
    const loggedUser = yield select(({ user }) => user.profile);

    const roles = loggedUser?.roles;

    const isAdmin = roles?.some(role => role.slug === 'administrator');
    const isStoreManager = roles?.some(role => role.slug === 'store-manager');
    const {
      data: { query: ip },
    } = yield call(Axios.get, 'https://extreme-ip-lookup.com/json/');
    if (!ip) {
      toast.error('Impossível finalizar a compra!');
      return null;
    }
    yield put(setIp(ip));

    const {
      data: { cart },
    } = yield call(api.get, `/shareable-carts/carts/${id}`, {
      headers: { 'X-Shareable-Token': token },
    });

    if (cart.store.use_malga) {
      /* eslint-disable */
      ((a, b, c, d, e, f, g) => {
        a['CsdpObject'] = e;
        (a[e] =
          a[e] ||
          function () {
            (a[e].q = a[e].q || []).push(arguments);
          }),
          (a[e].l = 1 * new Date());
        (f = b.createElement(c)), (g = b.getElementsByTagName(c)[0]);
        f.async = 1;
        f.src = d;
        g.parentNode.insertBefore(f, g);
      })(
        window,
        document,
        'script',
        '//device.clearsale.com.br/p/fp.js',
        'csdp'
      );
      csdp('app', cart.store.clearsale_app);
      csdp('outputsessionid', 'sessionIdCSDP');
      /* eslint-disable */
    }

    if (
      !loggedUser ||
      isAdmin ||
      isStoreManager ||
      cart.handled_by.id === loggedUser.id
    ) {
      yield put(setClient(cart.client));
      yield put(setCart(cart));
    } else {
      toast.error('Você não tem acesso a esse carrinho');
      history.push('/');
    }
  } catch (err) {
    toast.error(
      'Houve um problema ao carregar as informações do carrinho! Verifique se há bloqueadores de anúncio e localização, caso esteja ativado, desabilite para finalizar a compra',
      {
        autoClose: 12000,
      }
    );
  }
}

function* PatchCart({ payload: { data, nextTab } }) {
  const token = yield select(
    ({ shareable_cart }) => shareable_cart.cart.shareable_token
  );

  const id = yield select(({ shareable_cart }) => shareable_cart.cart.id);

  try {
    const {
      data: { cart },
    } = yield call(
      api.patch,
      `/shareable-carts/carts/${id}`,
      { ...data },
      {
        headers: {
          'X-Shareable-Token': token,
        },
      }
    );

    yield put(updateCart(cart));
    if (nextTab) {
      yield put(setActiveTab(nextTab));
    }
  } catch (err) {
    toast.error('Ocorreu um erro ao atualizar as informações do carrinho!');
  }
}

function* SetIsForDelivery({ payload: isForDelivery }) {
  const token = yield select(
    ({ shareable_cart }) => shareable_cart.cart.shareable_token
  );

  const id = yield select(({ shareable_cart }) => shareable_cart.cart.id);

  try {
    const {
      data: { cart },
    } = yield call(
      api.patch,
      `/shareable-carts/carts/${id}`,
      { is_for_delivery: isForDelivery },
      {
        headers: {
          'X-Shareable-Token': token,
        },
      }
    );

    yield put(updateCart({ is_for_delivery: cart.is_for_delivery }));

    toast.success('Tipo de entrega atualizado com sucesso!');
  } catch (err) {
    toast.error('Houve um problema ao atualizar o tipo de entrega');
  }
}

function* SetPaymentType({ payload: paymentType }) {
  const token = yield select(
    ({ shareable_cart }) => shareable_cart.cart.shareable_token
  );
  const id = yield select(({ shareable_cart }) => shareable_cart.cart.id);

  try {
    const {
      data: { cart },
    } = yield call(
      api.patch,
      `/shareable-carts/carts/${id}`,
      { payment_type: paymentType },
      {
        headers: {
          'X-Shareable-Token': token,
        },
      }
    );

    yield put(updateCart({ payment_type: cart.payment_type }));

    toast.success('Pagamento atualizado com sucesso!');
  } catch (err) {
    toast.error('Houve um problema ao atualizar o tipo de pagamento');
  }
}

function* SaveClient({ payload: { data, formRef } }) {
  const token = yield select(
    ({ shareable_cart }) => shareable_cart.cart.shareable_token
  );

  try {
    const {
      data: { client },
    } = yield call(api.post, `/shareable-carts/clients`, data, {
      headers: {
        'X-Shareable-Token': token,
      },
    });

    yield put(patchCart({ client_id: client.id }));
    yield put(setClient(client));

    // toast.success('Dados salvos com sucesso!');
    yield put(setActiveTab(2));
  } catch (err) {
    const errors = err?.response?.data;
    if (errors?.cpf) formRef?.current?.setFieldError('cpf', errors.cpf);
    if (errors?.email) formRef?.current?.setFieldError('email', errors.email);
    if (errors?.telephone)
      formRef?.current?.setFieldError('telephone', errors?.telephone);
    if (errors?.message) {
      // if (err.response?.status === 500) {
      //   yield put(handleAddressNotFound());
      // }
      toast.error(errors?.message);
    }
  }
}

function* updateClient({ payload: { data, formRef } }) {
  const token = yield select(
    ({ shareable_cart }) => shareable_cart.cart.shareable_token
  );

  try {
    const {
      data: { client },
    } = yield call(
      api.put,
      `/shareable-carts/clients/${data.id}`,
      {
        name: data.name,
        cpf: data.cpf,
        email: data.email,
        telephone: data.telephone,
      },
      {
        headers: {
          'X-Shareable-Token': token,
        },
      }
    );

    yield put(patchCart({ client_id: client.id }));
    yield put(setClient(client));
    // toast.success('Dados salvos com sucesso!');
    yield put(setActiveTab(2));
  } catch (err) {
    const errors = err?.response?.data;
    if (errors?.cpf) formRef?.current?.setFieldError('cpf', errors.cpf);
    if (errors?.email) formRef?.current?.setFieldError('email', errors.email);
    if (errors?.telephone)
      formRef?.current?.setFieldError('telephone', errors?.telephone);
    if (errors?.message) {
      // if (err.response?.status === 500) {
      //   yield put(handleAddressNotFound());
      // }
      toast.error(errors?.message);
    }
  }
}

function* setPixData({ payload: payment_data }) {
  const { cart_id, payment_type, page_state } = payment_data;
  const token = yield select(
    ({ shareable_cart }) => shareable_cart.cart.shareable_token
  );
  try {
    page_state.setLoadingQrCode(true);
    // page_state.setShowPixQrCodeModal(true);

    const {
      data: { cart },
    } = yield call(
      api.post,
      `/shareable-carts/carts/${cart_id}/finalize`,
      {
        payment_type,
      },
      {
        headers: {
          'X-Shareable-Token': token,
        },
      }
    );

    const {
      payment_process: { qrcode },
    } = cart;

    page_state.setPixQrCode(qrcode);
    page_state.setLoadingQrCode(false);
    yield put(setPixDataSuccess({ qrcode }));
  } catch (err) {
    toast.error(err?.message);
  }
}

function* Finalize({ payload }) {
  const token = yield select(
    ({ shareable_cart }) => shareable_cart.cart.shareable_token
  );

  const id = yield select(({ shareable_cart }) => shareable_cart.cart.id);
  const ip = yield select(({ shareable_cart }) => shareable_cart.ip);

  const payment_process = yield select(
    ({ shareable_cart }) => shareable_cart.payment_process
  );

  const data = {
    ...payment_process,
    ip,
    ignore_client_address: false,
  };

  const { page_state } = payload;
  page_state.setLoading(true);

  try {
    const {
      data: { cart },
    } = yield call(api.post, `/shareable-carts/carts/${id}/finalize`, data, {
      headers: {
        'X-Shareable-Token': token,
      },
    });
    yield put(setCart(cart));

    if (cart.payment_type === 104) {
      const shortenedURL = yield CreateShortenUrl(
        cart.payment_process.authentication_url
      );
      yield put(setCieloAuthURL(shortenedURL));

      yield put(setShowCieloModal(true));
    }

    yield put(setFinishedSuccessfully(true));
    if (
      cart.payment_type !== 105 &&
      cart.payment_type !== 106 &&
      cart.payment_type !== 110 &&
      cart.payment_type !== 112 &&
      cart.payment_type !== 114 &&
      cart.payment_type !== 118
    ) {
      yield put(setActiveTab(5));
    }
    page_state.setLoading(false);
  } catch (err) {
    yield put(setFinishedSuccessfully(false));
    const errorMessage =
      err.response?.data?.message ||
      'Erro desconhecido na finalização da compra.';

    const { message: formattedError, code: errorCode } =
      formatCheckoutError(errorMessage);
    const formatedError = page_state.formatCheckoutError
      ? page_state.formatCheckoutError(errorMessage)
      : errorMessage;

    toast.error(formatedError, { autoClose: 5000 });

    if (page_state.setError) {
      page_state.setError(formattedError);
    }

    page_state.setLoading(false);

    if (
      errorCode === 'UNKNOWN_ERROR' ||
      err.response?.data?.status !== 'finalizado'
    ) {
      try {
        const errorMessage =
          err?.message || 'Erro desconhecido na finalização da compra.';
        const errorStack = err?.stack || 'Sem stack trace disponível.';

        let rawErrorDetails;
        try {
          rawErrorDetails = JSON.stringify(
            err,
            Object.getOwnPropertyNames(err),
            2
          );
        } catch (serializationError) {
          rawErrorDetails = `Erro ao serializar: ${serializationError.message}`;
        }

        if (!rawErrorDetails || rawErrorDetails === '{}') {
          rawErrorDetails = `Erro: ${errorMessage}\nStack trace: ${errorStack}`;
        }

        const errorDetails = `
        **Erro capturado:**
        ${errorMessage}

        **Stack trace:**
        ${errorStack}

        **Detalhes brutos do erro:**
        ${rawErrorDetails}
        `;

        const debugData = {
          exception: errorDetails,
          timestamp: new Date().toISOString(),
          userAgent: navigator.userAgent,
          localStorageDebug: localStorage.getItem('checkout_debug'),
          client_data: data,
        };

        yield call(sendDebugEmail, debugData, id, token);
        console.log(
          'Um e-mail foi enviado para o suporte com os detalhes do erro desconhecido.'
        );
      } catch (emailError) {
        console.log(
          'Não foi possível enviar o e-mail de erro automaticamente.'
        );
        console.log(emailError);
      }
    }
  }
}

export default all([
  takeLatest(LOAD_CART, LoadCart),
  takeLatest(PATCH_CART, PatchCart),
  takeLatest(SET_IS_FOR_DELIVERY, SetIsForDelivery),
  takeLatest(SET_PAYMENT_TYPE, SetPaymentType),
  takeLatest(SAVE_CLIENT, SaveClient),
  takeLatest(UPDATE_CLIENT, updateClient),
  takeLatest(FINALIZE, Finalize),
  takeLatest(SET_PIX_DATA, setPixData),
]);
